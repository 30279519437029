import { Box, Text } from "@chakra-ui/layout";
import { useThemeData } from "hooks/useThemeData";
import Icon from "components/common/Icon";
import dayjs from "dayjs";
import { memo, ReactElement, useCallback, useEffect, useState } from "react";
import { Habit } from "models/habits";
import { useTranslation } from "react-i18next";
import { UnitSymbol } from "models/common";
import {
    convert,
    getType,
    getBaseUnitFromType,
} from "tools/si-unit/si-unit-utils";
import {
    CardIconName,
    CardType,
    HabitProgressMapInfo,
    IFilterSingleProgress,
} from "models/single-progress";
import { getLocaleString, getMonthKey } from "tools/single-progress";
interface UICardOverviewProps {
    cardType: CardType;
    iconName: CardIconName;
    habitProgressInfo: HabitProgressMapInfo;
    filterCurrent: IFilterSingleProgress;
    habit: Habit;
}

const CardOverviewCommon: React.FC<UICardOverviewProps> = ({
    cardType,
    iconName,
    habitProgressInfo,
    filterCurrent,
    habit,
}: UICardOverviewProps): ReactElement => {
    const { typography, colorScheme } = useThemeData();
    const { t, i18n } = useTranslation();

    const [dataCurrent, setDataCurrent] = useState<number>(0);
    const [dataPrev, setDataPrev] = useState<number>(0);
    const habitGoalCurrent = habitProgressInfo.dateToHabitGoalCurrentMap?.get(
        dayjs().format("YYYY-MM-DD")
    );
    const dataChanged = dataCurrent - dataPrev;

    const colorSubItem = () => {
        if (!dataChanged) return colorScheme.label.primary;
        if (
            cardType === "COMPLETED" ||
            cardType === "ZERO DAY" ||
            (habit?.habitType?.habitType !== "bad" && cardType === "TOTAL")
        )
            return dataChanged > 0
                ? colorScheme.supporting.success
                : colorScheme.supporting.error;
        if (
            cardType === "SKIPPED" ||
            cardType === "FAILED" ||
            (habit?.habitType?.habitType === "bad" && cardType === "TOTAL")
        )
            return dataChanged > 0
                ? colorScheme.supporting.error
                : colorScheme.supporting.success;
    };

    const textSubItem = (val: number) => {
        if (cardType === "TOTAL") {
            if (!habitGoalCurrent?.unit?.symbol)
                return `${val} ${t("measurement.unitTimes")}`;
            const unitSymbol = habitGoalCurrent.unit.symbol as UnitSymbol;
            const unitType = getType({ unitSymbol: unitSymbol });
            const baseUnit = getBaseUnitFromType({ type: unitType });
            const logValue = convert({
                source: baseUnit,
                target: unitSymbol,
                value: val,
            });
            let unit = unitSymbol;
            if (unitSymbol === "rep") unit = t("measurement.unitTimes");
            if (unitSymbol === "step" || unitSymbol === "steps")
                unit = t("measurement.unitSteps");

            return `${Math.round(logValue * 100) / 100} ${unit}`;
        } else {
            return getLocaleString(val, 'day', i18n.language)
        }
    };

    const titleCard = (cardType: CardType) => {
        let title = "";
        switch (cardType) {
            case "COMPLETED":
                title = t("common.complete");
                break;
            case "FAILED":
                title = t("common.failed");
                break;
            case "TOTAL":
                title = t("progress.dataDimensionTotal");
                break;
            case "ZERO DAY":
                title = t("common.zeroDay");
                break;
            case "SKIPPED":
                title = t("common.skipped");
                break;
            default:
                break;
        }
        return title
    };

    const getDataByCardTypeWithMonthFiltered = (
        filterMonthKey: string,
        cardType: CardType,
        habitProgressInfo: HabitProgressMapInfo
    ): number => {
        let data = 0;
        switch (cardType) {
            case "COMPLETED":
                data =
                    habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(
                        filterMonthKey
                    ) || 0;
                break;
            case "FAILED":
                data =
                    habitProgressInfo.dateToNumberOfFailedMonthMap?.get(
                        filterMonthKey
                    ) || 0;
                break;
            case "SKIPPED":
                data =
                    habitProgressInfo.dateToNumberOfSkippedMonthMap?.get(
                        filterMonthKey
                    ) || 0;
                break;
            case "TOTAL":
                data =
                    habitProgressInfo.dateToNumberLogValueMonthMap?.get(
                        filterMonthKey
                    ) || 0;
                break;
            case "ZERO DAY":
                data =
                    habitProgressInfo.dateToBadHabitZeroDayMap?.get(
                        filterMonthKey
                    ) || 0;
                break;
            default:
                break;
        }
        return data;
    };

    const getDataByCardTypeWithYearFiltered = useCallback(
        (payload: {
            year: number;
            habitStartDate: dayjs.Dayjs;
            cardType: CardType;
            habitProgressInfo: HabitProgressMapInfo;
        }) => {
            const { year, habitStartDate, cardType, habitProgressInfo } =
                payload;
            let data: number = 0;
            for (let month = 0; month < 12; month++) {
                if (
                    year === habitStartDate.year() &&
                    month < habitStartDate.month()
                )
                    continue;
                if (year === dayjs().year() && month > dayjs().month()) break;
                data += getDataByCardTypeWithMonthFiltered(
                    `${month}-${year}`,
                    cardType,
                    habitProgressInfo
                );
            }
            return data;
        },
        []
    );

    useEffect(() => {
        const filterType = filterCurrent.type;
        if (filterType === "MONTH") {
            const filterMonthKey = getMonthKey(filterCurrent.date);
            const filterMonthPrevKey = getMonthKey(
                filterCurrent.date.subtract(1, "months")
            );
            const dataOfMonthCurrent = getDataByCardTypeWithMonthFiltered(
                filterMonthKey,
                cardType,
                habitProgressInfo
            );
            const dataOfMonthPrev = getDataByCardTypeWithMonthFiltered(
                filterMonthPrevKey,
                cardType,
                habitProgressInfo
            );
            setDataCurrent(dataOfMonthCurrent);
            setDataPrev(dataOfMonthPrev);
        }
        if (filterType === "YEAR") {
            const habitStartDate = dayjs(habit?.startDate);
            const dataOfYearCurrent = getDataByCardTypeWithYearFiltered({
                year: filterCurrent.date.year(),
                habitStartDate,
                cardType,
                habitProgressInfo,
            });
            const dataOfYearPrev = getDataByCardTypeWithYearFiltered({
                year: filterCurrent.date.subtract(1, "year").year(),
                habitStartDate,
                cardType,
                habitProgressInfo,
            });
            setDataCurrent(dataOfYearCurrent);
            setDataPrev(dataOfYearPrev);
        }
    }, [
        habitProgressInfo,
        filterCurrent,
        cardType,
        habit?.startDate,
        getDataByCardTypeWithYearFiltered,
    ]);

    return (
        <Box
            display="grid"
            gridTemplateColumns="1fr"
            gridRowGap="6px"
            p="16.5px 16px"
            border={`1px solid ${colorScheme.platform.border}`}
            borderRadius="10px"
            alignContent="center"
            backgroundColor={colorScheme.background.primary[1]}
        >
            <Box display="flex" justifyContent="left">
                {!!iconName && (
                    <Box
                        mr="6px"
                        mt={
                            cardType === "ZERO DAY"
                                ? "-3px"
                                : cardType === "COMPLETED"
                                ? "1px"
                                : 0
                        }
                    >
                        <Icon
                            name={iconName}
                            fill={colorScheme.label.secondary}
                        />
                    </Box>
                )}
                <Text
                    textTransform="uppercase"
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    color={colorScheme.label.secondary}
                    {...typography.other.caption[2]}
                >
                    {habit?.habitType?.habitType === "bad" &&
                    cardType === "COMPLETED"
                        ? t("common.success")
                        : titleCard(cardType)}
                </Text>
            </Box>
            <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
                color={colorScheme.label.primary}
                {...typography.other.heading[4]}
            >
                {textSubItem(dataCurrent)}
            </Text>

            <Box display="flex" alignItems="center" h="15px">
                {!dataChanged && "---"}
                <Box
                    opacity={!dataChanged ? 0 : 1}
                    visibility={!dataChanged ? "hidden" : "visible"}
                    mr="4px"
                    w="8px"
                    mt="-2.555px"
                >
                    <Icon
                        name="ic-single-arrow"
                        fill={colorSubItem()}
                        style={{
                            transform:
                                dataChanged > 0
                                    ? "rotate(0deg)"
                                    : "rotate(180deg)",
                        }}
                    />
                </Box>
                <Text
                    textOverflow="ellipsis"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    color={colorSubItem()}
                    {...typography.other.title[5]}
                    opacity={!dataChanged ? 0 : 1}
                    visibility={!dataChanged ? "hidden" : "visible"}
                >
                    {textSubItem(Math.abs(dataChanged))}
                </Text>
            </Box>
        </Box>
    );
};
export default memo(CardOverviewCommon);
