import dayjs from 'dayjs';
import { UnitSymbol } from 'models/common';
import { HabitGoal } from 'models/habits';

export * from './card-overview'
export type IFilterSingleProgress = {
    index: number,
    text: string;
    type: "MONTH" | "YEAR";
    date: dayjs.Dayjs;
}

export interface IStreaks {
    startDate: string | null;
    endDate: string | null;
    count: number;
}

export interface IGoalUnit {
    symbol: string;
    type: string;
}
export interface IHabitNewGoal {
    periodicity?: string;
    unit?: IGoalUnit;
    value?: number;
    createdAt?: string;
}
export interface HabitProgressMapInfo {
    habitStartDate?: number | null | undefined;
    logInfoType?: string | undefined;
    dateToCheckInStatusMap?: Map<string, number | null>;
    dateToActualGoalValueMap?: Map<string, number | null>;
    dateToActualNoGoalValueMap?: Map<string, number | null>;
    dateToGoalValueMap?: Map<string, number | null>;
    dateToHabitGoalCurrentMap?: Map<string, HabitGoal | IHabitNewGoal | undefined | null>;
    dateToActualSymbolMap?: Map<string, string | null>;
    dateToLogValueWeekMap?: Map<string, number | null>;
    dateToLogValueMonthMap?: Map<string, number | null>;
    dateToActualCheckInStatus?: Map<string, number | null>;
    dateToNumberOfCheckInStatusWeek?: Map<string, number | null>;
    dateToNumberOfCheckInStatusMonth?: Map<string, number | null>;
    dateToGoalWeekMap?: Map<string, HabitGoal | IHabitNewGoal | null>;
    dateToGoalMonthMap?: Map<string, HabitGoal | IHabitNewGoal | null>;
    symbolDefault?: UnitSymbol | null;
    dateToNumberOfCompletedMonthMap?: Map<string, number>;
    dateToNumberOfSkippedMonthMap?: Map<string, number>;
    dateToNumberOfFailedMonthMap?: Map<string, number>;
    dateToLogValueYearMap?: Map<string, number>;
    dateToConditionIsCurrentStreakMap?: Map<string, boolean>
    dateToBadHabitZeroDayMap?: Map<string, number>
    dateToNumberLogValueMonthMap?: Map<string, number>
    dateToConditionDisplayHabitByRegularly?: Map<string, boolean>
    latestDayFailBadHabit?: { latestDay: dayjs.Dayjs | null, isUpdateValue: boolean };
}

export interface DateToCalendarInfo {
    class: string;
    goal?: HabitGoal | IHabitNewGoal | null;
    inProgress: number | null;
}

export interface SumHabitProgress {
    numberOfComplete?: number;
    numberOfSkip?: number;
    numberOfFail?: number;
}

export interface FilterCalendarOptions {
    index?: number;
    title?: string;
    type?: string;
    date?: dayjs.Dayjs;
}

export interface CalendarHeatMapInfo {
    month: dayjs.Dayjs | undefined;
    dataCalendar: Map<string, DateToCalendarInfo>;
}

export interface IStreaks {
    startDate: string | null;
    endDate: string | null;
    count: number;
}

export interface DataChartTrend {
    dataBarChart?: number[];
    dataLineChart?: number[];
    dateCategories?: string[];
    symbolDefault?: string;
    textRange?: string;
    isChartDisplay?: boolean;
    sumLogValue?: number;
}

export interface ChartSeriesInfo {
    name?: string;
    type?: string;
    data?: number[];
}

export enum CalendarClass {
    COMPLETED = 'completed',
    PREV_COMPLETED = 'pevCompleted',
    NEXT_COMPLETED = 'nextCompleted',
    MIDDLE_COMPLETED = 'middleCompleted',
    SINGLE_COMPLETED = 'singleCompleted',
    IN_PROGRESS = 'progress',
    SKIP = 'skip',
    FAIL = 'fail',
    NONE = 'none',
    BLOCK = 'blocked',
}

export enum FilterCalendarOptionType {
    MONTH = 'month',
    YEAR = 'year',
}

export enum HABIT_GOAL_PERIODICITY {
    DAILY = 'daily',
    WEEKLY = 'weekly',
    MONTHLY = 'monthly',
}

export enum AppScreenSize {
    WIDTH = 1031,
    WIDTH_TABLET = 1024,
    WIDTH_MOBILE = 600
  }
  
  export type FirstWeekDay = 'sunday' | 'monday';