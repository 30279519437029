import { HabitLog, HabitLogType } from "./../../models/habit-logs/habit-logs";
import dayjs from "dayjs";
import { UnitSymbol, UnitType } from "models/common";
import {
    getBaseUnitFromType as newGetBaseUnitFromType,
    getType as newGetType,
    convert,
} from "tools/si-unit/si-unit-utils";
import { TFunction } from "i18next";
import { FirstWeekDay } from "models/single-progress";

export const getDayKey = (currentDate: dayjs.Dayjs): string => {
    return currentDate.format("YYYY-MM-DD");
};

export const getWeekKey = (currentDate: dayjs.Dayjs, firstDayOfWeek: FirstWeekDay): string => {
    if(firstDayOfWeek === 'monday'){
        const startOfWeek = dayjs(currentDate).startOf('week')
        if(getDayKey(currentDate) === getDayKey(startOfWeek)){
            return `w-${currentDate.subtract(1, 'day').week()}-${currentDate.year()}`
        }
    }
    return `w-${currentDate.week()}-${currentDate.year()}`;
};

export const getWeekExactlyByMonth = (currentDate: dayjs.Dayjs): string => {
    return `w-${currentDate.week()}-${currentDate.month()}-${currentDate.year()}`;
};

export const getMonthKey = (currentDate: dayjs.Dayjs): string => {
    return `${currentDate.month()}-${currentDate.year()}`;
};

export const getIsConditionPassHabitLog = (payload: {
    habitLog: HabitLog;
    habitStartDate: number | null | undefined;
    logType: HabitLogType | undefined;
    habitGoalSymbol: UnitSymbol | null | undefined
}) => {
    const { habitLog, habitStartDate, logType, habitGoalSymbol } = payload;
    if (!habitLog?.unitSymbol || !habitGoalSymbol) {
        return false;
    }
    const timesTampsHabitStartDate: number = dayjs(
        dayjs(habitStartDate).format("YYYY-MM-DD")
    ).valueOf();
    const timesTampsHabitLog: number = dayjs(habitLog.startAt).valueOf();
    const habitLogType: string = habitLog?.type !== "manual" ? "auto" : "manual";

    const habitLogUnitType: UnitType = newGetType({
        unitSymbol: habitLog.unitSymbol,
    });

    const baseUnitSymbolOfLog: string = newGetBaseUnitFromType({
        type: habitLogUnitType,
    });

    const goalUnitType: UnitType = newGetType({
        unitSymbol: habitGoalSymbol,
    });

    const goalBaseUnitType = newGetBaseUnitFromType({
        type: goalUnitType,
    });

    return (
        timesTampsHabitLog >= timesTampsHabitStartDate &&
        logType === habitLogType &&
        goalBaseUnitType === baseUnitSymbolOfLog
    );
};

export const getValueByUnitGoalCurrent = ({
    val,
    sourceUnitSymbol,
    targetUnitSymbol,
}: {
    val: number;
    sourceUnitSymbol: UnitSymbol | null | undefined;
    targetUnitSymbol: UnitSymbol | null | undefined;
}) => {
    if (!sourceUnitSymbol || !targetUnitSymbol) return 0;
    try {
        const logValue = convert({
            source: sourceUnitSymbol,
            target: targetUnitSymbol,
            value: val,
        });
        return Math.round(logValue)
    } catch (error) {
        return val;
    }
};

export const getUnitSymbolLocalization = (
    unitSymbol: UnitSymbol | null | undefined,
    t: TFunction
) => {
    let unit = unitSymbol;
    if (unitSymbol === "rep") unit = t("measurement.unitTimes");
    if (unitSymbol === "step" || unitSymbol === "steps")
        unit = t("measurement.unitSteps");
    return unit;
};

export const isToday = (date: dayjs.Dayjs) => {
    return date.format('DDMMYYYY') === dayjs().format('DDMMYYYY')
}
