import { Box, Center, Flex } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import { IStreaks } from 'models/single-progress';
import React, { memo, ReactElement, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { _dayjs } from 'tools/extended-dayjs';
interface HabitStreakItemProps {
  streak?: IStreaks;
  maxStreakCount?: number;
  habitColor?: string | null | undefined;
}

const commonStyleGridItem: React.CSSProperties = {
  whiteSpace: 'nowrap',
};

const widthGridItem = (count?: number, maxCount?: number): string => {
  return `calc(100% * ${(count || 1) / (maxCount || 1)})`;
};

const HabitStreakItem: React.FC<HabitStreakItemProps> = ({
  streak,
  maxStreakCount,
  habitColor,
}: HabitStreakItemProps): ReactElement => {
  const { typography, colorScheme } = useThemeData();
  const { i18n } = useTranslation();

  const formatDate = useCallback(
    (date: string): string => {
      return _dayjs(date).locale(i18n.language).format('MMM D');
    },
    [i18n.language],
  );

  return (
    <Flex m="0 auto" justifyContent="center" alignItems="center" mt="13px">
      <Box
        style={commonStyleGridItem}
        minW="35px"
        w={widthGridItem(streak?.count, maxStreakCount)}
        bg={!!habitColor ? habitColor : '#2a67f4'}
        borderRadius="5px"
        m="0 14px"
        height="28px"
        position="relative"
      >
        <Box
          style={commonStyleGridItem}
          h="28px"
          textAlign="right"
          position="absolute"
          zIndex="99"
          left="-63px"
          display="flex"
          alignItems="center"
          color={colorScheme.label.secondary}
          {...typography.other.title[5]}
        >
          {formatDate(String(streak?.startDate))}
        </Box>
        <Center h="100%" color={colorScheme.universal.white}>
          {streak?.count}
        </Center>
        <Box
          style={commonStyleGridItem}
          h="28px"
          textAlign="left"
          position="absolute"
          top="0"
          zIndex="99"
          right="-63px"
          display="flex"
          alignItems="center"
          color={colorScheme.label.secondary}
          {...typography.other.title[5]}
        >
          {formatDate(String(streak?.endDate))}
        </Box>
      </Box>
    </Flex>
  );
};

export default memo(HabitStreakItem);
