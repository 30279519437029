import { Box } from '@chakra-ui/layout';
import React, { memo, ReactElement } from 'react';
import { AppScreenSize, CardIconName, CardType, HabitProgressMapInfo, IFilterSingleProgress } from 'models/single-progress';
import Card from './card_overview_common.single_progress';
import { Habit } from 'models/habits';
import { useMediaQuery } from '@chakra-ui/react';

interface CardOverviewWrapperProps {
  cardOverView: {
    cardType: CardType;
    iconName: CardIconName;
  }[];
  habitProgressInfo: HabitProgressMapInfo;
  filterCurrent: IFilterSingleProgress;
  habit: Habit;
}

const CardOverviewWrapper: React.FC<CardOverviewWrapperProps> = ({
  cardOverView,
  habitProgressInfo,
  filterCurrent,
  habit,
}: CardOverviewWrapperProps): ReactElement => {

  const [isLargerThanTablet] = useMediaQuery(`(min-width: ${AppScreenSize.WIDTH_TABLET}px)`);

  return (
    <Box
      display="grid"
      gridTemplateColumns={isLargerThanTablet && cardOverView.length > 2 ? 'repeat(4, 1fr)' : '1fr 1fr'}
      gridGap="12px"
      mt="12px"
      mb="12px"
      className="card-overview"
    >
      {cardOverView.map((item) => (
        <Card
          cardType={item.cardType}
          iconName={item.iconName}
          habitProgressInfo={habitProgressInfo}
          filterCurrent={filterCurrent}
          habit={habit}
          key={item.cardType}
        />
      ))}
    </Box>
  );
};
export default memo(CardOverviewWrapper);
