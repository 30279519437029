import dayjs from 'dayjs';
import { _dayjs } from 'tools/extended-dayjs';
import { CalendarClass, DateToCalendarInfo, FilterCalendarOptionType, HabitProgressMapInfo } from 'models/single-progress';

enum HABIT_PROGRESS {
    NONE,
    SKIP,
    COMPLETE,
    FAILED,
    IN_PROGRESS,
}

enum FIRST_DAY_OF_WEEK_IN_APP {
    SUNDAY = 1,
    MONTH = 2,
}

const getPrevDate = (date: Date): dayjs.Dayjs => {
    return _dayjs(date).add(-1, 'days');
};

const getNextDate = (date: Date): dayjs.Dayjs => {
    return _dayjs(date).add(1, 'days');
};

const getTimesTamps = (date: Date | number, format: string): number => {
    return _dayjs(_dayjs(date).format(format)).valueOf();
};

const getDataCalendarInfo = (date: Date, habitProgressInfo: HabitProgressMapInfo): DateToCalendarInfo | undefined => {
    const {
        habitStartDate,
        dateToActualGoalValueMap,
        dateToCheckInStatusMap,
        dateToGoalValueMap,
        dateToHabitGoalCurrentMap,
    } = habitProgressInfo;
    // if (habitStartDate) {
        const _habitStartDate = habitStartDate || new Date().setDate(new Date().getDate() + 1);
        const dateFormat = 'YYYY-MM-DD';
        const dateId: string = _dayjs(date).format(dateFormat);
        const isDayBlock: boolean =
            getTimesTamps(date, dateFormat) >= getTimesTamps(_habitStartDate, dateFormat) &&
            getTimesTamps(date, dateFormat) <= getTimesTamps(new Date(), dateFormat);
        let classNow = '';
        if (!isDayBlock) {
            classNow = CalendarClass.BLOCK;
        } else {
            const prevDate: dayjs.Dayjs = getPrevDate(date);
            const nextDate: dayjs.Dayjs = getNextDate(date);
            const habitProgress: number | null = dateToCheckInStatusMap?.get(dateId) || null;
            const prevHabitProgress: number | null = dateToCheckInStatusMap?.get(_dayjs(prevDate).format(dateFormat)) || null;
            const nextHabitProgress: number | null = dateToCheckInStatusMap?.get(_dayjs(nextDate).format(dateFormat)) || null;

            classNow = getClassOfDay(habitProgress);
            if (classNow === CalendarClass.COMPLETED) {
                const prevClass: string = getClassOfDay(prevHabitProgress);
                const nextClass: string = getClassOfDay(nextHabitProgress);
                classNow = getClassCompleted(prevClass, nextClass, classNow);
            }
        }
        const goal = dateToHabitGoalCurrentMap?.get(dateId) || null;
        const inProgress: number =
            ((dateToActualGoalValueMap?.get(dateId) || 0) / (dateToGoalValueMap?.get(dateId) || 0)) * 100 || 0;
        const result: DateToCalendarInfo = {
            class: classNow,
            goal,
            inProgress,
        };
        return result;
    // }
};

const setDaysOfMonth = (
    month: number,
    year: number,
    habitProgressInfo: HabitProgressMapInfo,
    dateToCalendarInfoMap: Map<string, DateToCalendarInfo>,
): void => {
    // const { habitStartDate } = habitProgressInfo;
    // if (habitStartDate) {
        const monthIndex: number = month;
        const date: Date = new Date(year, monthIndex, 1);
        while (date.getMonth() === monthIndex) {
            const dateFormat = 'YYYY-MM-DD';
            const dateId: string = _dayjs(date).format(dateFormat);
            const result: DateToCalendarInfo | undefined = getDataCalendarInfo(date, habitProgressInfo);
            if (result) dateToCalendarInfoMap.set(dateId, result);
            date.setDate(date.getDate() + 1);
        }
    // }
};

const setDaysOfWeek = (
    date: Date,
    habitProgressInfo: HabitProgressMapInfo,
    dateToCalendarInfoMap: Map<string, DateToCalendarInfo>,
    firstDayOfWeek: number,
    type: string,
): void => {
    
    // if (habitProgressInfo?.habitStartDate) {
        // first day of week
        const _date = new Date(date.getFullYear(), date.getMonth(), 1);
        firstDayOfWeek === FIRST_DAY_OF_WEEK_IN_APP.MONTH
            ? date.setDate(date.getDate() - date.getDay() + 1)
            : date.setDate(date.getDate() - date.getDay());

        if (type === 'fistDayOfMonth') {
            if (date.getTime() > _date.getTime()) {
                firstDayOfWeek === FIRST_DAY_OF_WEEK_IN_APP.MONTH
                    ? date.setDate(date.getDate() - date.getDay() - 6)
                    : date.setDate(date.getDate() - date.getDay() - 7);
            }
        }

        for (let i = 0; i < 7; i++) {
            const dateId: string = _dayjs(date).format('YYYY-MM-DD');
            if (dateToCalendarInfoMap.has(dateId)) {
                date.setDate(date.getDate() + 1);
                continue;
            }
            const result = getDataCalendarInfo(date, habitProgressInfo);
            if (result) {
                dateToCalendarInfoMap.set(dateId, result);
            }
            date.setDate(date.getDate() + 1);
        }
    // }
};

const getClassOfDay = (habitProgress: number | null | undefined) => {
    if (!habitProgress) {
        return CalendarClass.NONE;
    }

    if (habitProgress === HABIT_PROGRESS.NONE) {
        return CalendarClass.NONE;
    }

    if (habitProgress === HABIT_PROGRESS.SKIP) {
        return CalendarClass.SKIP;
    }

    if (habitProgress === HABIT_PROGRESS.FAILED) {
        return CalendarClass.FAIL;
    }

    if (habitProgress === HABIT_PROGRESS.IN_PROGRESS) {
        return CalendarClass.IN_PROGRESS;
    }

    return CalendarClass.COMPLETED;
};

const getClassCompleted = (prevClass: string, nextClass: string, currentClass: string): string => {
    if (currentClass !== CalendarClass.COMPLETED) {
        return currentClass;
    }
    if (prevClass !== CalendarClass.COMPLETED && nextClass !== CalendarClass.COMPLETED) {
        return CalendarClass.SINGLE_COMPLETED;
    }

    if (prevClass !== CalendarClass.COMPLETED && nextClass === CalendarClass.COMPLETED) {
        return CalendarClass.PREV_COMPLETED;
    }

    if (nextClass !== CalendarClass.COMPLETED && prevClass === CalendarClass.COMPLETED) {
        return CalendarClass.NEXT_COMPLETED;
    }

    if (prevClass === CalendarClass.COMPLETED && nextClass === CalendarClass.COMPLETED) {
        return CalendarClass.MIDDLE_COMPLETED;
    }

    return CalendarClass.COMPLETED;
};

export const getCalendar = (
    month: number,
    year: number,
    type: string,
    habitProgressInfo: HabitProgressMapInfo,
    firstDayOfWeek: number,
): { dateToCalendarInfoMap: Map<string, DateToCalendarInfo> } => {
    const dateToCalendarInfoMap = new Map<string, DateToCalendarInfo>();

    if (type === FilterCalendarOptionType.MONTH) {
        setDaysOfWeek(new Date(year, month, 1), habitProgressInfo, dateToCalendarInfoMap, firstDayOfWeek, 'fistDayOfMonth');
    }
    setDaysOfMonth(month, year, habitProgressInfo, dateToCalendarInfoMap);
    if (type === FilterCalendarOptionType.MONTH) {
        setDaysOfWeek(
            new Date(year, month + 1, 0),
            habitProgressInfo,
            dateToCalendarInfoMap,
            firstDayOfWeek,
            'lastDayOfMonth',
        );
    }

    return { dateToCalendarInfoMap };
};
