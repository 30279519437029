import { Box } from '@chakra-ui/layout';
import React, { memo, ReactElement } from 'react';
import CalendarYearlyItem from 'components/ui/single-progress/main/calendar/CalendarYearlyItem';
import { CalendarHeatMapInfo } from 'models/single-progress';

interface CalendarYearlyProps {
  calendarHeatMapInfo: CalendarHeatMapInfo[];
  habitColor: string
}

const CalendarYearly: React.FC<CalendarYearlyProps> = ({ calendarHeatMapInfo, habitColor }: CalendarYearlyProps): ReactElement => {
  
  return (
    <Box pl="17px" pr="12px">
      {calendarHeatMapInfo.map((CalendarHeatMapItem, index) => {
    return <CalendarYearlyItem CalendarHeatMapItem={CalendarHeatMapItem} habitColor={habitColor} key={`b-${index}`} />;
      })}
    </Box>
  );
};
export default memo(CalendarYearly);
