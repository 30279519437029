interface IFontSizeValue {
  REGULAR: number;
  MEDIUM: number;
  SEMI_BOLD: number;
  BOLD: number;
  EXTRA_BOLD: number;
  BLACK: number;
}

const fontWeight: IFontSizeValue = {
  REGULAR: 400,
  MEDIUM: 500,
  SEMI_BOLD: 600,
  BOLD: 700,
  EXTRA_BOLD: 800,
  BLACK: 900,
};

export { fontWeight };
