import dayjs from "dayjs";
import { HabitLog } from "models/habit-logs";
import { HabitGoal } from "models/habits";
import { HabitProgressMapInfo } from "models/single-progress";

export interface IStreaks {
    startDate: string | null;
    endDate: string | null;
    count: number;
}
export interface IHabitLogFilter {
    habitLog: HabitLog;
    logValue: number;
    isNoGoal?: boolean;
}
export interface StreakProperties {
    subStartDate: string | null;
    subCount: number;
    subEndDate: string | null;
}

export interface HabitProgressInfo {
    dateId: string;
    checkInStatus: number;
    actualGoalValue: number | null;
    goalValue: number | null;
    habitGoalCurrent: HabitGoal | null | undefined;
    actualSymbol: string | null;
    actualCheckInStatus: number | null;
    actualNoGoalValue: number | null;
    latestDayFailBadHabit: dayjs.Dayjs | null;
}
export interface SingleProgressBaseData {
    streaks?: IStreaks[] | undefined;
    habitProgressInfo?: HabitProgressMapInfo;
}

export enum CheckInsStatus {
    NONE = 0,
    SKIP = 1,
    COMPLETE = 2,
    FAIL = 3,
    PROGRESS = 4,
}