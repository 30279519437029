import { Habit, HabitLogInfo, HabitRemind, HabitTypeWithRawValue, TimeOfDayWithRawValue } from 'models/habits';
import { goalSingleModelMapper, goalsModelMapper } from './habit-goal-firebase-model-parser';
import { numberValueParser, stringValueParser } from 'tools/parser';

import { HabitLogType } from 'models/habit-logs';
import { TimesOfDay } from 'models/common';
import { habitCheckinsModelMapper } from './habit-checkins-model-parser';
import memoizeOne from 'memoize-one';

export const habitModelMapper = memoizeOne(({ key, rawValue }: { key: string; rawValue: any }): Habit | undefined => {
  if (!rawValue) return undefined;
  return {
    id: key,
    name: habitNameMapper(rawValue['name']),
    createdAt: habitCreatedAtMapper(rawValue['createdAt']),
    checkins: habitCheckinsModelMapper({ rawValue: rawValue['checkins'] }),
    isArchived: rawValue['isArchived'],
    accentColor: habitAccentColorMapper(rawValue['accentColor']),
    goal: goalSingleModelMapper({ rawGoalSingleValue: rawValue['goal'] }),
    goals: goalsModelMapper({ rawGoalsValue: rawValue['goals'] }),
    regularly: stringValueParser(rawValue['regularly']),
    startDate: habitStartDateMapper(rawValue['startDate']),
    timeOfDay: habitTimeOfDayMapper(rawValue['timeOfDay']),
    habitType: habitTypeMapper(rawValue['habitType']),
    iconNamed: stringValueParser(rawValue['iconNamed']),
    logInfo: habitLogInfoMapper(rawValue['logInfo']),
    priority: numberValueParser(rawValue['priority']),
    priorityByArea: stringValueParser(rawValue['priorityByArea']),
    remind: habitRemindMapper(rawValue['remind']),
    targetFolderId: stringValueParser(rawValue['targetFolderId']),
    templateIdentifier: stringValueParser(rawValue['templateIdentifier']),
    description: stringValueParser(rawValue['description']),
  };
});

const habitNameMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) {
    return undefined;
  }

  return rawValue;
});

const habitCreatedAtMapper = memoizeOne((rawValue: any): string | undefined => {
  if (!rawValue) {
    return undefined;
  }
  return rawValue;
});

const habitAccentColorMapper = memoizeOne((rawValue: any): string => {
  if (!rawValue) {
    return '';
  }
  if (rawValue.charAt(0) !== '#') {
    return `#${rawValue}`;
  }
  return rawValue;
});

const habitStartDateMapper = memoizeOne((rawValue: any): number | undefined => {
  if (!rawValue) return undefined;
  return rawValue * 1000;
});

const habitLogInfoMapper = memoizeOne((rawValue: any): HabitLogInfo | undefined => {
  if (!rawValue) return undefined;
  let type: HabitLogType;

  switch (rawValue['type']) {
    case 'manual':
      type = 'manual';
      break;
    case 'auto':
      type = 'auto';
      break;
    default:
      type = '';
      break;
  }

  return {
    type,
    links: rawValue['links'],
  };
});

const habitRemindMapper = memoizeOne((rawValue: any): HabitRemind | undefined => {
  if (!rawValue) return undefined;
  return {
    timeTriggers: rawValue['timeTriggers'],
  };
});

const habitTimeOfDayMapper = memoizeOne((rawValue: any): TimeOfDayWithRawValue | undefined => {
  if (!rawValue) return undefined;

  switch (rawValue) {
    case TimesOfDay.MORNING:
      return {
        rawValue,
        timeOfDay: 'morning',
      };
    case TimesOfDay.AFTERNOON:
      return {
        rawValue,
        timeOfDay: 'afternoon',
      };
    case TimesOfDay.EVENING:
      return {
        rawValue,
        timeOfDay: 'evening',
      };
    default:
      return {
        rawValue,
        timeOfDay: 'all',
      };
  }
});

const habitTypeMapper = memoizeOne((rawValue: any): HabitTypeWithRawValue | undefined => {
  if (!rawValue) return undefined;

  if (rawValue === 1) {
    return {
      rawValue,
      habitType: 'good',
    };
  }

  if (rawValue === 2) {
    return {
      rawValue,
      habitType: 'bad',
    };
  }
});
