import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { darkMode, lightMode } from 'tools/common/colorPreferences';

const breakpoints = createBreakpoints({
  sm: '320px',
  md: '768px',
  lg: '960px',
  xl: '1200px',
});

const textStyles = {
  heading1: {
    fontFamily: 'Inter',
    fontSize: 30,
    fontWeight: 700,
  },
  heading2: { fontFamily: 'Inter', fontSize: 26, fontWeight: 700 },
  heading3: { fontFamily: 'Inter', fontSize: 22, fontWeight: 600 },
  heading4: { fontFamily: 'Inter', fontSize: 20, fontWeight: 600 },
  heading5: { fontFamily: 'Inter', fontSize: 18, fontWeight: 600 },
  heading6: { fontFamily: 'Inter', fontSize: 16, fontWeight: 500 },
  caption1: { fontFamily: 'Inter', fontSize: 13, fontWeight: 600 },
  caption2: { fontFamily: 'Inter', fontSize: 11, fontWeight: 600 },
  subHeading: { fontFamily: 'Inter', fontSize: 16, fontWeight: 400 },
  body: { fontFamily: 'Inter', fontSize: 15, fontWeight: 400 },
  footnote: { fontFamily: 'Inter', fontSize: 13, fontWeight: 400 },
  chartEntryLabel: { fontFamily: 'Inter', fontSize: 10, fontWeight: 500 },
  title1: { fontFamily: 'Inter', fontSize: 20, fontWeight: 500 },
  subTitle1: { fontFamily: 'Inter', fontSize: 16, fontWeight: 400 },
  title2: { fontFamily: 'Inter', fontSize: 18, fontWeight: 500 },
  subTitle2: { fontFamily: 'Inter', fontSize: 16, fontWeight: 400 },
  title3: { fontFamily: 'Inter', fontSize: 16, fontWeight: 500 },
  subTitle3: { fontFamily: 'Inter', fontSize: 14, fontWeight: 400 },
  title4: { fontFamily: 'Inter', fontSize: 14, fontWeight: 500 },
  subTitle4: { fontFamily: 'Inter', fontSize: 13, fontWeight: 400 },
  title5: { fontFamily: 'Inter', fontSize: 13, fontWeight: 500 },
  navigationTitle: { fontFamily: 'Inter', fontSize: 17, fontWeight: 600 },
  primaryButton: { fontFamily: 'Inter', fontSize: 16, fontWeight: 500 },
  secondaryButton: { fontFamily: 'Inter', fontSize: 14, fontWeight: 500 },
  navigationButton: { fontFamily: 'Inter', fontSize: 16, fontWeight: 500 },
};

const colors = {
  checkBox: {
    200: lightMode.ACCENT_COLOR,
    500: lightMode.ACCENT_COLOR,
  },
};

export const chakraTheme = extendTheme({
  styles: {
    global: (props: any) => {
      return {
        'html, body': {
          backgroundColor: props.colorMode === 'light' ? lightMode.BACKGROUND : darkMode.BACKGROUND,
          color: props.colorMode === 'light' ? lightMode.TEXT_COLOR : darkMode.TEXT_COLOR,
        },
        svg: {
          maxHeight: '100%',
        },
        a: {
          color: props.colorMode === 'light' ? lightMode.ACCENT_COLOR : darkMode.ACCENT_COLOR,
          fontSize: '15px',
          fontWeight: '400',
        },
      };
    },
  },
  fonts: {
    body: "'Inter', sans-serif",
    heading: "'Inter', sans-serif",
  },
  config: {
    initialColorMode: 'light',
    useSystemColorMode:  true,
  },
  colors: colors,
  textStyles: textStyles,
  breakpoints,
});
