import { ApexOptions } from "apexcharts";
import dayjs from "dayjs";

export const optionChart: ApexOptions = {
  chart: {
    type: 'bar',
    zoom: {
      enabled: false,
    },
    toolbar: {
      show: false,
    },
  },
  colors: ['#2A67F4', '#D23737'],
  grid: {
    show: true,
    // borderColor: '#EDEDED', //#3B3B3B
    strokeDashArray: 10,
  },
  dataLabels: {
    enabled: false,
  },
  fill: {
    colors: ['#2A67F4'],
    type: 'solid',
    opacity: [1, 1],
  },
  stroke: {
    width: [4, 2],
    dashArray: [0, 8],
    curve: 'stepline',
  },
  plotOptions: {
    bar: {
      borderRadius: 2,
      dataLabels: {
        maxItems: 10,
      },
    },
  },
  xaxis: {
    type: 'category',
    tickAmount: 10,
    axisTicks: {
      show: false,
    },
    categories: [],
    labels: {
      style: {
        colors: '#7B7C7C',
        fontFamily: 'Inter, sans-serif',
        fontSize: '11px',
      },
      formatter: function (value, timestamp, opts) {
        return !!value ? value?.slice(0, value.length - 4) : '';
      },
      hideOverlappingLabels: true,
    },
  },
  yaxis: {
    min: 0,
    labels: {
      style: {
        colors: '#7B7C7C',
        fontFamily: 'Inter, sans-serif',
        fontSize: '11px',
      },
      formatter(val: number) {
        if (val === 0 || val >= 1) {
          return String(Math.round(val)?.toFixed(0));
        } else if (val < 1) {
          return String(Math.round(val)?.toFixed(2));
        } else return '';
      },
    },
  },
  legend: {
    show: false,
  },
};

const randomIntFromInterval = (min: number = 1, max: number = 100): number => {
  return Math.floor(Math.random() * (max - min + 1) + min)
}

export const dataBarChartEmpty = (): { values: number[], days: string[] } => {
  const arr1 = [];
  const arr2 = []
  let index = 0
  for (index; index < 32; index++) {
    arr1.push(randomIntFromInterval());
    arr2.push(dayjs().subtract(index, 'days').format('MMM D YYYY').toUpperCase())
  }
  return { values: arr1, days: arr2 }
}
