import { Box, Text } from '@chakra-ui/layout';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { Image, useMediaQuery } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import MenuItemLayout from 'components/common/menu/MenuItem';
import MenuListLayout from 'components/common/menu/MenuList';
import { Popover, PopoverTrigger } from '@chakra-ui/popover';
import PullDownButton from 'components/common/button/pushdown-button.component';
import Icon from 'components/common/Icon';
import { useColorModeValue } from '@chakra-ui/color-mode';
import { Habit } from 'models/habits';
import dayjs from 'dayjs';
import { AppScreenSize, IFilterSingleProgress } from 'models/single-progress';

import Seo from 'components/common/SEO';

import avatarDefault from 'assets/icons/default-avatar2.svg';

import logo1x from 'assets/images/header-logo-1x.png';
import logo2x from 'assets/images/header-logo-2x.png';
import logo3x from 'assets/images/header-logo-3x.png';

interface Props {
  habit: Habit;
  filterCurrent: IFilterSingleProgress;
  userInfo: { name?: string; firstName?: string; lastName?: string; firstWeekday?: number; profileImageURL?: string };
  setFilterCurrent: (val: IFilterSingleProgress) => void;
}

const Header: React.FC<Props> = ({ habit, filterCurrent, userInfo, setFilterCurrent }: Props): ReactElement => {
  const { colorScheme } = useThemeData();
  const iconColor = useColorModeValue(colorScheme.label.secondary, colorScheme.universal.white);
  const [isShowFilterOptions, setIsShowFilterOptions] = useState(false);
  const [filterOptions, setFilterOptions] = useState<
    {
      index: number;
      text: string;
      type: 'MONTH' | 'YEAR';
      date: dayjs.Dayjs;
    }[]
  >([]);
  const [textFilterPicked, setTextFilterPicked] = useState(filterCurrent.text);

  const [isLargerThanTablet] = useMediaQuery(`(min-width: ${AppScreenSize.WIDTH_TABLET}px)`);

  useEffect(() => {
    if (filterCurrent) {
      setTextFilterPicked(filterCurrent.text);
    }
  }, [filterCurrent]);

  const userName = () => {
    return userInfo?.firstName || userInfo?.lastName
      ? `${userInfo?.firstName || ''} ${userInfo?.lastName || ''}`
      : userInfo?.name || '';
  };

  const urlAvatar = () => {
    return userInfo?.profileImageURL || avatarDefault;
  };

  const getTitle = () => {
    const name = userName();
    const habitName = habit?.name;

    if (!!name && habitName) {
      return `${habitName} - ${name}`;
    }
    return 'Habitify';
  };

  useEffect(() => {
    if (habit) {
      let options: IFilterSingleProgress[] = [];
      const habitStartDate = dayjs(habit.startDate);
      const LIMIT_FILTER_OPTIONS = 6;
      for (let i = 0; i < LIMIT_FILTER_OPTIONS; i++) {
        const tempMonth: dayjs.Dayjs = dayjs().subtract(i, 'months');
        const tempYear: dayjs.Dayjs = dayjs().subtract(i, 'years');
        if (!habitStartDate.isAfter(tempMonth, 'month')) {
          options.push({
            index: i,
            text: tempMonth.format('MMMM, YYYY'),
            type: 'MONTH',
            date: tempMonth,
          });
        }
        if (!habitStartDate.isAfter(tempYear, 'year')) {
          options.push({
            index: i + LIMIT_FILTER_OPTIONS,
            text: tempYear.format('YYYY'),
            type: 'YEAR',
            date: tempYear,
          });
        }
      }
      options = options.sort((item1, item2) => item1.index - item2.index);
      setFilterOptions(options);
    }
  }, [habit]);

  const handleOnChangeFilter = (val: { index: number; text: string; type: 'MONTH' | 'YEAR'; date: dayjs.Dayjs }) => {
    setTextFilterPicked(val.text);
    setIsShowFilterOptions(false);
    setFilterCurrent(val);
  };

  const logoOnClick = () => {
    window.location.href = 'https://habitify.me';
  };

  return (
    <>
      <Seo title={getTitle()}></Seo>
      <Box>
        <Box borderBottom={`1px solid ${colorScheme.platform.border}`} padding={isLargerThanTablet ? 0 : '0 9px'}>
          <Box
            maxW={`${AppScreenSize.WIDTH}px`}
            margin="0 auto"
            height="60px"
            display="flex"
            alignItems="center"
            onClick={() => logoOnClick()}
          >
            <Image srcSet={`${logo1x} 1x, ${logo2x} 2x, ${logo3x} 3x`} objectFit="cover"></Image>
            <Text ml="10px" fontSize="20px" fontWeight="800" lineHeight="24.2px" color={colorScheme.label.primary}>
              Habitify
            </Text>
          </Box>
        </Box>
        <Box borderBottom={`1px solid ${colorScheme.platform.border}`} padding={isLargerThanTablet ? 0 : '0 12px'}>
          <Box
            h="65px"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            maxW={`${AppScreenSize.WIDTH}px`}
            margin="0 auto"
          >
            <Box width="50%" display="flex" alignItems="center" className="topBar-habit-name__maxWidth">
              <Box width="34px" height="34px" mr="12px">
                <Image
                  width="34px"
                  height="34px"
                  borderRadius={userInfo?.profileImageURL ? '50%' : 'unset'}
                  src={urlAvatar()}
                  fallbackSrc={urlAvatar()}
                ></Image>
              </Box>
              <Box width="calc(100% - 48px)">
                <Text
                  color={colorScheme.label.primary}
                  fontSize="18px"
                  fontWeight="600"
                  lineHeight="21.78px"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  title={habit?.name || ''}
                >
                  {habit?.name}
                </Text>
                <Text
                  color={colorScheme.label.secondary}
                  mt="2px"
                  fontSize="14px"
                  lineHeight="21px"
                  fontWeight="400"
                  textOverflow="ellipsis"
                  whiteSpace="nowrap"
                  overflow="hidden"
                >
                  {userName()}
                </Text>
              </Box>
            </Box>
            <Box display="flex" ml="10px">
              <Popover
                isOpen={isShowFilterOptions}
                placement="bottom-end"
                strategy="fixed"
                onOpen={() => setIsShowFilterOptions(true)}
                onClose={() => setIsShowFilterOptions(false)}
              >
                <PopoverTrigger>
                  <PullDownButton
                    title={textFilterPicked}
                    color={colorScheme.label.primary}
                    rightIconColor={iconColor}
                    border={`1px solid ${colorScheme.other.border}`}
                    leftIcon={<Icon name="ic-calendar" fill={iconColor} />}
                    backgroundColor={colorScheme.platform.control.background}
                    _hover={{
                      bg: colorScheme.platform.control.background,
                      color: `${colorScheme.label.primary}`,
                    }}
                    _active={{
                      bg: colorScheme.platform.control.background,
                      color: colorScheme.label.primary,
                    }}
                  />
                </PopoverTrigger>
                {filterOptions && (
                  <MenuListLayout style={{ width: '208px', overflow: 'hidden' }}>
                    {filterOptions.map((val) => (
                      <MenuItemLayout
                        hasIconLeft={false}
                        key={val.index}
                        folderName="journal"
                        title={val.text}
                        bgColor={val.index === filterCurrent.index ? colorScheme.accent.primary : 'unset'}
                        isSelected={val.index === filterCurrent.index ? true : false}
                        onClick={() => handleOnChangeFilter(val)}
                      />
                    ))}
                  </MenuListLayout>
                )}
              </Popover>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};
export default memo(Header);
