import { Box, Text } from '@chakra-ui/layout';
import { ApexOptions } from 'apexcharts';
import { useThemeData } from 'hooks/useThemeData';
import { ChartSeriesInfo } from 'models/single-progress';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { dataBarChartEmpty, optionChart } from './chart_trend.model.single_progress';
import ReactApexCharts from 'react-apexcharts';

const HabitChartTrendEmpty: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { colorScheme } = useThemeData();

  const [chartOption, setChartOption] = useState<ApexOptions>(optionChart);
  const [chartSeries, setChartSeries] = useState<ChartSeriesInfo[]>([]);

  useEffect(() => {
    const dataChartEmpty = dataBarChartEmpty();

    const xaxisBorder = {
      show: true,
      color: colorScheme.background.primary[2],
      offsetX: -1,
      offsetY: 0,
    };

    const yaxisBorder = {
      show: true,
      color: colorScheme.background.primary[2],
      offsetX: 1,
      offsetY: -1,
    };

    const series: ChartSeriesInfo[] = [
      {
        name: 'Log Value',
        type: 'bar',
        data: dataChartEmpty.values,
      },
      {
        name: 'Goal Value',
        type: 'line',
        data: [],
      },
    ];

    const chartOptions: ApexOptions = {
      colors: [colorScheme.background.primary[2]],
      fill: {
        colors: [colorScheme.background.primary[2]],
      },
      grid: {
        borderColor: colorScheme.background.primary[2],
      },
      tooltip: {
        enabled: false,
      },
      xaxis: {
        axisBorder: xaxisBorder,
        labels: {
          formatter: function () {
            return '.....';
          },
          style: {
            colors: colorScheme.background.primary[2],
          },
        },
      },
      yaxis: {
        axisBorder: yaxisBorder,
        labels: {
          show: false,
        },
      },
    };

    setChartOption(chartOptions);
    setChartSeries(series);
  }, [colorScheme.background.primary, colorScheme.label.secondary]);

  return (
    <Box position="relative">
      <Box
        width="100%"
        position="absolute"
        top="50%"
        left="50%"
        textAlign="center"
        transform="translate(-50%, -50%)"
        zIndex={9}
        fontSize="15px"
        fontWeight="400"
        lineHeight="22.5px"
        color={colorScheme.label.secondary}
      >
        <Text
          display="inline-block"
          height="47px"
          lineHeight="47px"
          borderRadius="10px"
          padding="0 10px"
          backgroundColor={colorScheme.background.primary[2]}
        >
          {t('progress.progressEmpty3')}
        </Text>
      </Box>
      <ReactApexCharts options={chartOption} series={chartSeries} width="100%" height={250} />
    </Box>
  );
};
export default memo(HabitChartTrendEmpty);
