import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { resources } from 'localization/resources';
import React, { ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Routes from 'views/Routes';

dayjs.extend(localeData);

const App: React.FC = (): ReactElement => {
  const { i18n } = useTranslation();

  useEffect(() => {
    const lang =
      window?.navigator?.language || localStorage.getItem('i18nextLng') || navigator.language || dayjs.locale() || 'en';
    const _resources = resources as any;
    const isLangSupport = _resources[lang];
    const langCurrent = isLangSupport ? lang : 'en';
    if (langCurrent) {
      i18n.changeLanguage(langCurrent);
      import(`dayjs/locale/${langCurrent}.js`).then((res) => {
        dayjs.locale({
          ...res,
          weekStart: 0,
        });
      });
    }
  }, [i18n]);

  return <Routes />;
};

export default App;
