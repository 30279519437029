import { Box, Flex, Text } from '@chakra-ui/layout';
import { useThemeData } from 'hooks/useThemeData';
import React, { memo, ReactElement, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

const HabitStreakItemEmpty: React.FC = (): ReactElement => {
  const { t } = useTranslation();
  const { typography, colorScheme } = useThemeData();

  const list = [3.2, 1.8, 2.8, 6, 5, 4.5];
  const widthGridItem = (count?: number, maxCount: number = 6): string => {
    return `calc(100% * ${(count || 1) / (maxCount || 1)})`;
  };

  const textEmpty = (count: number) => {
    return count === Math.max(...list) ? t('progress.progressEmpty3') : ''
  }

  useEffect(() => {
    const el = document.querySelector('.streak_width');
    const width = el?.getBoundingClientRect()?.width;
    el?.setAttribute('style', `max-width: ${width}px`);
  }, []);

  return (
    <>
      {list.map((count, index) => (
        <Flex key={index} justifyContent="center" alignItems="center" mt="13px">
          <Box
            key={index}
            display="flex"
            justifyContent="center"
            w={widthGridItem(count)}
            maxW={widthGridItem(count)}
            minW="35px"
            borderRadius="5px"
            m="0 14px"
            height={count === Math.max(...list) ? '41px' : '29px'}
            backgroundColor={colorScheme.background.primary[2]}
            {...typography.normal.title[3]}
            alignItems="center"
            position="relative"
            className={count === Math.max(...list) ? 'streak_width' : ''}
          >
            <Text
              textOverflow="ellipsis"
              whiteSpace="nowrap"
              overflow="hidden"
              maxW="300px"
              title={textEmpty(count)}
              color={colorScheme.label.secondary}
            >
              {textEmpty(count)}
            </Text>
          </Box>
        </Flex>
      ))}
    </>
  );
};
export default memo(HabitStreakItemEmpty);
