import { Box } from '@chakra-ui/layout';
import { useThemeData } from 'hooks/useThemeData';
import { memo, ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import { Spinner, useMediaQuery } from '@chakra-ui/react';

import {
  AppScreenSize,
  CardIconName,
  CardType,
  FirstWeekDay,
  HabitProgressMapInfo,
  IFilterSingleProgress,
  IStreaks,
} from 'models/single-progress';
import { useTranslation } from 'react-i18next';
import { habitModelMapper } from 'tools/habits';
import { getListHabitGoal } from 'tools/habit-progress';
import { CheckInsStatus, getDayKey, getHabitLogs, getSingleBaseData } from 'tools/single-progress';
import { useLocation } from 'react-router-dom';
import { Habit } from 'models/habits';
import axios from 'axios';
import dayjs from 'dayjs';

import TopBar from './top-bar/top_bar.single_progress';
import CardCurrentStreak from './card-overview/current_streak.single_progress';
import Calendar from './Calendar';
import CardOverviewWrapper from './card-overview/card_overview_wrapper';
import HabitChartTrend from './charts/HabitChartTrend';
import HabitStreaksChart from './charts/habit_streaks.single_progress';
import Footer from './footer';

const SingleProgress: React.FC = (): ReactElement => {
  const location = useLocation();
  const { colorScheme } = useThemeData();
  const { i18n } = useTranslation();

  const [habitColor, setHabitColor] = useState<string | null | undefined>('');
  const [firstDayOfWeek, setFirstDayOfWeek] = useState<FirstWeekDay>('sunday');
  const [shareId, setShareId] = useState('');
  const [habit, setHabit] = useState<Habit>({});
  const [habitLog, setHabitLog] = useState<any>({});
  const [userInfo, setUserInfo] = useState({});

  const defaultFilter: IFilterSingleProgress = useMemo(() => {
    return {
      index: 0,
      text: dayjs().subtract(0, 'month').locale(i18n.language).format('MMMM, YYYY'),
      type: 'MONTH',
      date: dayjs(),
    };
  }, [i18n.language]);

  const [filterCurrent, setFilterCurrent] = useState<IFilterSingleProgress>(defaultFilter);
  const [currentStreak, setCurrentStreak] = useState<IStreaks | null>(null);
  const [habitListStreaks, setHabitListStreaks] = useState<IStreaks[]>([]);
  const [cardOverView, setCardOverView] = useState<{ cardType: CardType; iconName: CardIconName }[]>([]);
  const [habitProgressInfo, setHabitProgressInfo] = useState<HabitProgressMapInfo>({});
  const [isLoading, setIsLoading] = useState(true);

  const [isLargerThanTablet] = useMediaQuery(`(min-width: ${AppScreenSize.WIDTH_TABLET}px)`);
  const [isLargerThanMobile] = useMediaQuery(`(min-width: ${AppScreenSize.WIDTH_MOBILE}px)`);

  const getNumberSecondOfOneDay = (args: { h: number; m: number; s: number; type: 'START_DATE' | 'END_DATE' }) => {
    const { h, m, s, type } = args;
    return type === 'START_DATE' ? ((23 - h) * 60 + (59 - m)) * 60 + (60 - s) : (h * 60 + m) * 60 + s;
  };

  const getStreaks = useCallback((streaks: IStreaks[], habit: Habit, habitProgressInfo: HabitProgressMapInfo) => {
    const isQuitGoal = getIsQuitGoal(habit, habitProgressInfo);
    const LIMIT_STREAK = 6;
    let result = streaks
      .filter((streak) => streak.count > 1)
      .sort((streak1, streak2) => {
        if (streak2.count === streak1.count) {
          return dayjs(String(streak1.endDate)).valueOf() - dayjs(String(streak2.endDate)).valueOf();
        }
        return streak2.count - streak1.count;
      });
    if (result.length > LIMIT_STREAK) {
      result = result.filter((streak: IStreaks, key: number) => key < LIMIT_STREAK);
    }
    if (isQuitGoal) {
      const resultStreak: IStreaks[] = [];
      const habitStartDate = dayjs(habit?.startDate);
      const checkInStatusMap = habitProgressInfo.dateToCheckInStatusMap;
      if (streaks.length) {
        result.map((streak) => {
          const _streak = streak;
          const startDate = dayjs(streak.startDate);
          const endDate = dayjs(streak.endDate);
          const startDateKey = getDayKey(startDate);
          const endDateKey = getDayKey(endDate);
          const habitStartDateKey = getDayKey(habitStartDate);
          const nowDateKey = getDayKey(dayjs());
          if (startDateKey === habitStartDateKey && endDateKey === nowDateKey) {
            const checkInsStartDate = checkInStatusMap?.get(startDate.format('YYYY-MM-DD'));
            const checkInsEndDate = checkInStatusMap?.get(endDate.format('YYYY-MM-DD'));
            if (checkInsEndDate === CheckInsStatus.COMPLETE || checkInsStartDate === CheckInsStatus.COMPLETE) {
              const SECOND_FULL_DAY = 86400;
              const isBlockNowDate = checkInsEndDate === CheckInsStatus.SKIP || checkInsEndDate === CheckInsStatus.FAIL;
              const isBlockHabitStartDate =
                checkInsStartDate === CheckInsStatus.SKIP || checkInsStartDate === CheckInsStatus.FAIL;
              const numSecondStartDate = getNumberSecondOfOneDay({
                h: habitStartDate.hour(),
                m: habitStartDate.minute(),
                s: habitStartDate.second(),
                type: 'START_DATE',
              });

              const numSecondEndDate = getNumberSecondOfOneDay({
                h: dayjs().hour(),
                m: dayjs().minute(),
                s: dayjs().second(),
                type: 'END_DATE',
              });

              const numberOfSecond = isBlockNowDate
                ? numSecondStartDate
                : isBlockHabitStartDate
                ? numSecondEndDate
                : numSecondStartDate + numSecondEndDate;
              if (numberOfSecond < SECOND_FULL_DAY) {
                _streak.count = _streak.count - 1;
              }
            }
          }
          if (startDateKey === habitStartDateKey && endDateKey !== nowDateKey) {
            const checkIns = checkInStatusMap?.get(startDate.format('YYYY-MM-DD'));
            if (checkIns === CheckInsStatus.COMPLETE) {
              _streak.count = _streak.count - 1;
            }
          }
          if (endDateKey === nowDateKey && startDateKey !== habitStartDateKey) {
            const checkIns = checkInStatusMap?.get(endDate.format('YYYY-MM-DD'));
            if (checkIns === CheckInsStatus.COMPLETE) {
              _streak.count = _streak.count - 1;
            }
          }
          return resultStreak.push(_streak);
        });
        result = resultStreak.filter((streak) => streak.count > 1);
      }
    }
    return result;
  }, []);

  useEffect(() => {
    const arrPathName = location.pathname.split('/');
    const shareId = arrPathName[1];
    shareId && setShareId(shareId);
  }, [location.pathname]);

  useEffect(() => {
    if (!!!shareId) {
      setFilterCurrent({
        index: 0,
        text: dayjs().subtract(0, 'month').locale(i18n.language).format('MMMM, YYYY'),
        type: 'MONTH',
        date: dayjs(),
      });
      setTimeout(() => {
        setIsLoading(false);
      }, 2000);
      return;
    }
    const BASE_URL = "https://us-central1-project-8491986773398252429.cloudfunctions.net/shareAPIEndpoint/progress/";
    axios
      .get(`${BASE_URL}?shareId=${shareId}`)
      .then((payload) => {
        const { data, status } = payload;
        if (status === 200) {
          const NUMBER_MONDAY = 2;
          const { habit, habitLogs, userInfo } = data;
          const _habit = habitModelMapper({ key: habit.id, rawValue: habit });
          const firstWeekDay: FirstWeekDay =
            userInfo?.firstWeekday && Number(userInfo.firstWeekday) === NUMBER_MONDAY ? 'monday' : 'sunday';
          setFirstDayOfWeek(firstWeekDay);
          _habit && setHabit(_habit);
          userInfo && setUserInfo(userInfo);
          habitLogs && setHabitLog(Object.entries(habitLogs));
          setFilterCurrent({
            index: 0,
            text: dayjs().subtract(0, 'month').locale(i18n.language).format('MMMM, YYYY'),
            type: 'MONTH',
            date: dayjs(),
          });
        }else {
          console.warn('something wrong')
          setIsLoading(false);
        }
      })
      .catch((e) => {
        console.warn('ERROR =>', e);
        setIsLoading(false);
      });
    // }
  }, [shareId, i18n.language]);

  useEffect(() => {
    setFilterCurrent(defaultFilter);
  }, [defaultFilter]);

  useEffect(() => {
    habit && setHabitColor(!!habit?.accentColor ? habit?.accentColor : colorScheme.accent.primary);
  }, [habit, colorScheme?.accent.primary]);

  useEffect(() => {
    if (Object.keys(habit).length) {
      const { goal, goals, startDate } = habit;
      const habitGoalList = getListHabitGoal({
        goal,
        goals,
        startDate,
        firstDayOfWeek,
      });
      if (firstDayOfWeek) {
        const habitLogsMap = getHabitLogs(habit, habitLog, habitGoalList, firstDayOfWeek);
        const habitStreaks = getSingleBaseData(habit, habitLogsMap, habitGoalList, false, firstDayOfWeek);
        const { streaks, habitProgressInfo } = habitStreaks;
        if (streaks && habitProgressInfo) {
          setCurrentStreak(getCurrentStreak(streaks, habitProgressInfo));
          setHabitListStreaks(getStreaks(streaks, habit, habitProgressInfo));
          setIsLoading(false);
        }
        habitProgressInfo && setHabitProgressInfo(habitProgressInfo);
      }
    }
    return () => {
      setCurrentStreak(null);
      setHabitListStreaks([]);
      setHabitProgressInfo({});
    };
  }, [firstDayOfWeek, getStreaks, habit, habitLog]);

  useEffect(() => {
    setCardOverView([
      { cardType: 'COMPLETED', iconName: 'ic-single-completed' },
      { cardType: 'FAILED', iconName: 'ic-single-failed' },
      { cardType: 'SKIPPED', iconName: 'ic-single-skipped' },
      { cardType: 'TOTAL', iconName: '' },
    ]);
    if (Object.keys(habitProgressInfo).length) {
      const isBadHabit = habit?.habitType?.habitType === 'bad';
      const isBadHabitQuitGoal =
        habitProgressInfo.dateToHabitGoalCurrentMap?.get(dayjs().format('YYYY-MM-DD'))?.value === 0;
      if (isBadHabit && isBadHabitQuitGoal) {
        setCardOverView([
          { cardType: 'COMPLETED', iconName: 'ic-single-completed' },
          { cardType: 'FAILED', iconName: 'ic-single-failed' },
        ]);
      }
      if (isBadHabit && !isBadHabitQuitGoal) {
        setCardOverView([
          { cardType: 'COMPLETED', iconName: 'ic-single-completed' },
          { cardType: 'FAILED', iconName: 'ic-single-failed' },
          { cardType: 'ZERO DAY', iconName: 'ic-single-zero-day' },
          { cardType: 'TOTAL', iconName: '' },
        ]);
      }
    }
  }, [habitProgressInfo, habit?.habitType]);

  const getCurrentStreak = (streaks: IStreaks[], habitProgressInfo: HabitProgressMapInfo) => {
    const recentStreak = streaks[0];
    const endDateRecentStreak = recentStreak?.endDate;
    if (
      endDateRecentStreak &&
      habitProgressInfo.dateToConditionIsCurrentStreakMap?.get(dayjs(endDateRecentStreak).format('YYYY-MM-DD'))
    )
      return recentStreak;
    return null;
  };

  const getIsQuitGoal = (habit: Habit, habitProgressInfo: HabitProgressMapInfo) => {
    const isBadHabit = habit.habitType?.habitType === 'bad';
    const isBadHabitQuitGoal =
      habitProgressInfo.dateToHabitGoalCurrentMap?.get(dayjs().format('YYYY-MM-DD'))?.value === 0;
    return isBadHabit && isBadHabitQuitGoal;
  };

  return (
    <>
    <Box
      width="100%"
      height="100%"
      backgroundColor={colorScheme.background.primary[1]}
      className="single-progress__content"
      minH="100vh"
    >
      <TopBar habit={habit} filterCurrent={filterCurrent} userInfo={userInfo} setFilterCurrent={setFilterCurrent} />
      {!isLoading ? (
        <Box
          minH="calc(100vh - 290px)"
          backgroundColor={colorScheme.background.primary[4]}
          padding={isLargerThanTablet ? 0 : '0 12px'}
        >
          <Box
            display="grid"
            gridTemplateColumns={isLargerThanMobile ? '1fr 1fr' : '1fr'}
            gridColumnGap="16px"
            pt={isLargerThanTablet ? '16px' : '12px'}
            pb={isLargerThanTablet ? '21px' : '12px'}
            maxW={AppScreenSize.WIDTH + 'px'}
            justifyContent="center"
            margin="0 auto"
          >
            <Box>
              <CardCurrentStreak currentStreak={currentStreak} habit={habit} habitProgressInfo={habitProgressInfo} />
              <CardOverviewWrapper
                cardOverView={cardOverView}
                habitProgressInfo={habitProgressInfo}
                filterCurrent={filterCurrent}
                habit={habit}
              />
              <Calendar
                habitProgressInfo={habitProgressInfo}
                habit={habit}
                filterCurrent={filterCurrent}
                habitColor={habitColor}
                firstDayOfWeek={firstDayOfWeek}
              />
            </Box>
            <Box>
              <HabitChartTrend
                habit={habit}
                habitColor={habitColor}
                habitProgressInfo={habitProgressInfo}
                firstDayOfWeek={firstDayOfWeek}
              ></HabitChartTrend>
              {<HabitStreaksChart streaks={habitListStreaks} habitColor={habitColor} />}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box minH="calc(100vh - 290px)" display="flex" justifyContent="center" alignItems="center">
          <Spinner thickness="2.2px" color={colorScheme.accent.primary} size="lg" />
        </Box>
      )}
      {isLargerThanTablet && <Footer />}
    </Box>
    </>
  );
};
export default memo(SingleProgress);
