import { Box, Text } from '@chakra-ui/layout';
import { ReactElement } from 'hoist-non-react-statics/node_modules/@types/react';
import { useThemeData } from 'hooks/useThemeData';
import icCurrentStreak from 'assets/icons/ic-current-streak.svg';
import dayjs from 'dayjs';
import { memo, useEffect, useState } from 'react';
import { Habit } from 'models/habits';
import { BAD_HABIT_GOAL_VALUE } from 'models/habit-progress';
import { CheckInsStatus, getDayKey, getDescriptionBadHabitPending, getLocaleString, IStreaks } from 'tools/single-progress';
import { useTranslation } from 'react-i18next';
import { HabitProgressMapInfo } from 'models/single-progress';

interface Props {
  currentStreak: IStreaks | null;
  habit: Habit;
  habitProgressInfo: HabitProgressMapInfo;
}

const CardCurrentStreak: React.FC<Props> = ({ currentStreak, habit, habitProgressInfo }: Props): ReactElement => {
  const { t, i18n } = useTranslation();
  const { typography, colorScheme } = useThemeData();
  const [textCurrentStreak, setTextCurrentStreak] = useState('');
  const [isDisplayFromDateToday, setIsShowFromDateToday] = useState(true);

  useEffect(() => {
    let interval: NodeJS.Timer;
    const isBadHabit = habit?.habitType?.habitType === 'bad';
    const habitQuitGoal =
      habitProgressInfo.dateToHabitGoalCurrentMap?.get(dayjs().format('YYYY-MM-DD'))?.value ===
      BAD_HABIT_GOAL_VALUE.QUIT;
    const isStreak = habitProgressInfo.dateToConditionDisplayHabitByRegularly?.get(getDayKey(dayjs()));
    const nowCheckInStatus = habitProgressInfo.dateToCheckInStatusMap?.get(getDayKey(dayjs()));

    if (nowCheckInStatus === CheckInsStatus.FAIL && isStreak) {
      setTextCurrentStreak(getLocaleString(0, 'day', i18n.language));
      setIsShowFromDateToday(false);
      return;
    }
    setIsShowFromDateToday(true);
    if (isBadHabit && habitQuitGoal) {
      const latestDayFailBadHabit = habitProgressInfo.latestDayFailBadHabit?.latestDay;
      let _latestDayFailBadHabit = latestDayFailBadHabit?.toISOString();
      if (nowCheckInStatus === CheckInsStatus.SKIP && latestDayFailBadHabit) {
        const hour = dayjs().hour();
        const mins = dayjs().minute();
        const second = dayjs().second();
        _latestDayFailBadHabit = latestDayFailBadHabit
          .add(hour, 'hour')
          .add(mins, 'minute')
          .add(second, 'second')
          .toISOString();
      }

      const text = getDescriptionBadHabitPending(_latestDayFailBadHabit, i18n.language);
      text && setTextCurrentStreak(text);
      interval = setInterval(() => {
        const text = getDescriptionBadHabitPending(_latestDayFailBadHabit, i18n.language);
        text && setTextCurrentStreak(text);
      }, 1000);
    } else {
      const text = getLocaleString(currentStreak?.count || 0, 'day', i18n.language);
      setTextCurrentStreak(text);
    }
    return () => {
      clearInterval(interval);
    };
  }, [
    habit,
    habitProgressInfo.dateToCheckInStatusMap,
    habitProgressInfo.dateToHabitGoalCurrentMap,
    i18n.language,
    habitProgressInfo.dateToConditionDisplayHabitByRegularly,
    currentStreak,
    habitProgressInfo.latestDayFailBadHabit,
  ]);

  return (
    <Box
      className="card-current-streak"
      display="grid"
      gridTemplateColumns="1fr auto"
      gridColumnGap="20px"
      padding="18px 12px 18px 20px"
      border={`1px solid ${colorScheme.platform.border}`}
      borderRadius="5px"
      alignItems="center"
      backgroundColor={colorScheme.background.primary[1]}
    >
      <Box display="flex" alignItems="center">
        <Box minW="23px">
          <img src={icCurrentStreak} alt="" />
        </Box>
        <Box ml="12px">
          <Text textTransform="uppercase" mb="6px" color={colorScheme.label.secondary} {...typography.other.caption[2]}>
            {t('common.currentStreak')}
          </Text>
          <Text {...typography.other.heading[5]}>{textCurrentStreak}</Text>
        </Box>
      </Box>
      {isDisplayFromDateToday && (
        <Box
          backgroundColor={colorScheme.background.primary[2]}
          borderRadius="5px"
          padding="7px 8.5px"
          textTransform="uppercase"
          maxH="27px"
          display="flex"
          justifyContent="center"
          alignItems="center"
          color={colorScheme.label.secondary}
          opacity={currentStreak?.count ? 1 : 0}
          visibility={currentStreak?.count ? 'visible' : 'hidden'}
          {...typography.other.caption[2]}
        >
          {t('edithabit.startDateFrom', {
            args1: dayjs(currentStreak?.startDate).format('MMM DD, YYYY'),
          })}
        </Box>
      )}
    </Box>
  );
};
export default memo(CardCurrentStreak);
