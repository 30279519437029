import { Box, Link, Text } from '@chakra-ui/layout';
import { Image } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { useThemeData } from 'hooks/useThemeData';

import logo1x from "assets/images/footer-logo-1x.png";
import logo2x from "assets/images/footer-logo-2x.png";
import logo3x from "assets/images/footer-logo-3x.png";

import appleStore1x from "assets/images/apple-store-1x.png";
import appleStore2x from "assets/images/apple-store-2x.png";
import appleStore3x from "assets/images/apple-store-3x.png";

import googlePlay1x from "assets/images/google-play-1x.png";
import googlePlay2x from "assets/images/google-play-2x.png";
import googlePlay3x from "assets/images/google-play-3x.png";

const Footer: React.FC = (): ReactElement => {
  const { colorScheme, typography } = useThemeData();

  return (
    <Box borderTop={`1px solid ${colorScheme.platform.border}`}>
      <Box maxW="1031px" margin="0 auto">
        <Box height="123px" display="grid" gridTemplateColumns="429px 1fr" alignItems="center">
          <Box display="grid" gridTemplateColumns="auto 1fr" gridColumnGap="16px" ml="24px">
            <Image srcSet={`${logo1x} 1x, ${logo2x} 2x, ${logo3x} 3x`} objectFit="cover"></Image>
            <Box>
              <Text color={colorScheme.label.primary} {...typography.emphasized.title[5]}>
                Build new habits with habitify
              </Text>
              <Text color={colorScheme.label.secondary} mt="6px" {...typography.other.title[4]}>
                The code that you write and the systems that you build are maior contributing
              </Text>
            </Box>
          </Box>
          <Box
            display="grid"
            gridTemplateColumns="max-content max-content"
            justifyContent="flex-end"
            alignItems="center"
          >
            <Link href="https://apps.apple.com/us/app/habitify-habit-tracker/id1111447047#?platform=iphone" _focus={{ outline: 'unset' }}>
              <Image srcSet={`${appleStore1x} 1x, ${appleStore2x} 2x, ${appleStore3x} 3x`} objectFit="cover"></Image>
            </Link>
            <Link href="https://play.google.com/store/apps/details?id=co.unstatic.habitify" _focus={{ outline: 'unset' }}>
              <Image srcSet={`${googlePlay1x} 1x, ${googlePlay2x} 2x, ${googlePlay3x} 3x`} objectFit="cover"></Image>
            </Link>
          </Box>
        </Box>

        <Box
          height="39px"
          display="grid"
          gridTemplateColumns="1fr 1fr"
          alignItems="center"
          borderTop={`1px solid ${colorScheme.platform.border}`}
        >
          <Text color={colorScheme.label.secondary} {...typography.other.title[5]}>
            Copyright 2021 Habitify
          </Text>
          <Box
            display="grid"
            gridTemplateColumns="max-content max-content max-content"
            justifyContent="flex-end"
            gridColumnGap="20px"
            color={colorScheme.label.secondary}
            {...typography.other.title[4]}
          >
            <Link href="https://habitify.me" _focus={{ outline: 'unset' }}>
              <Text>Home</Text>
            </Link>
            <Link href="https://habitify.me/blog" _focus={{ outline: 'unset' }}>
              <Text>Blog</Text>
            </Link>
            <Link href="mailto:contact@habitify.me" _focus={{ outline: 'unset' }}>
              <Text>Contact Us</Text>
            </Link>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
export default Footer;
