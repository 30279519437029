import { UnitSymbol } from '../common';
import { GoalPeriodicity } from '../habits';

export type HabitProgress = 'none' | 'skipped' | 'completed' | 'failed' | 'in_progress';

export type BadHabitGoalType = 'quit' | 'no-more-than';

export type BadHabitPastDaysStreak = {
  streakComplete?: number | null;
  streakFail?: number | null;
};

export type HabitCurrentProgress = {
  habitId?: string | null;
  isOneTimePerDayHabit?: boolean | null;
  isNoGoalHabit?: boolean | null;
  currentStreak?: number | null;
  habitGoalValue?: number;
  habitActualLogValue?: number;
  habitGoalPeriodicity?: GoalPeriodicity | null;
  habitGoalSymbol?: UnitSymbol | null;
  habitProgress?: HabitProgress | null;
  latestDayFailBadHabit?: string | null;
  badHabitGoalType?: BadHabitGoalType | null;
  badHabitPastDaysStreak?: BadHabitPastDaysStreak | null;
};

export type HabitsCurrentProgress = {
  [habitId: string]: HabitCurrentProgress;
};

export enum BAD_HABIT_GOAL_VALUE {
  QUIT = 0,
  NO_MORE_THAN = 1,
}
