import { Box } from '@chakra-ui/layout';
import CalendarMonthly from 'components/ui/single-progress/main/calendar/CalendarMonthly';
import CalendarYearly from 'components/ui/single-progress/main/calendar/CalendarYearly';
import dayjs from 'dayjs';
import { Habit } from 'models/habits';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useThemeData } from 'hooks/useThemeData';
import { getCalendar } from 'tools/single-progress';
import {
  CalendarHeatMapInfo,
  DateToCalendarInfo,
  FilterCalendarOptionType,
  FirstWeekDay,
  HabitProgressMapInfo,
} from 'models/single-progress';

interface CalendarProps {
  habitProgressInfo: HabitProgressMapInfo;
  habit: Habit;
  habitColor: string | null | undefined;
  filterCurrent: {
    index: number;
    text: string;
    type: 'MONTH' | 'YEAR';
    date: dayjs.Dayjs;
  };
  firstDayOfWeek: FirstWeekDay;
}

const Calendar: React.FC<CalendarProps> = ({
  habitProgressInfo,
  habit,
  habitColor,
  filterCurrent,
  firstDayOfWeek,
}: CalendarProps): ReactElement => {
  const { typography, colorScheme } = useThemeData();

  const [calendarGridMapInfo, setCalendarGridMapInfo] = useState<Map<string, DateToCalendarInfo>>(new Map());

  const [calendarHeatMapInfo, setCalendarHeatMapInfo] = useState<CalendarHeatMapInfo[]>([]);

  useEffect(() => {
    const { type, date } = filterCurrent;
    const month = date.month();
    const year = date.year();
    const _firstDayOfWeek = firstDayOfWeek === 'monday' ? 2 : 1;

    if (type !== 'YEAR') {
      const result: {
        dateToCalendarInfoMap: Map<string, DateToCalendarInfo>;
      } = getCalendar(month, year, FilterCalendarOptionType.MONTH, habitProgressInfo, _firstDayOfWeek);
      if (result) {
        const { dateToCalendarInfoMap } = result;
        setCalendarGridMapInfo(dateToCalendarInfoMap);
      }
    } else {
      const habitStartDate = dayjs(habit?.startDate);
      const heatMapData: CalendarHeatMapInfo[] = [];

      for (let month = 0; month < 12; month++) {
        if (year === habitStartDate.year() && month < habitStartDate.month()) {
          continue;
        }
        if (year === dayjs().year() && month > dayjs().month()) {
          break;
        }
        const result = getCalendar(month, year, FilterCalendarOptionType.YEAR, habitProgressInfo, _firstDayOfWeek);
        heatMapData.push({
          month: dayjs(new Date(year, month, 1)),
          dataCalendar: result.dateToCalendarInfoMap,
        });
      }
      setCalendarHeatMapInfo(heatMapData);
    }
  }, [habitProgressInfo, firstDayOfWeek, filterCurrent, habit?.startDate]);

  return (
    <Box
      textAlign="left"
      mb="12px"
      pt="17px"
      pb="10px"
      border={`1px solid ${colorScheme.platform.border}`}
      borderRadius="10px"
      {...typography.emphasized.title[1]}
      backgroundColor={colorScheme.background.primary[1]}
    >
      {calendarGridMapInfo.size > 0 && filterCurrent.type === 'MONTH' && (
        <CalendarMonthly
          calendarGridMapInfo={calendarGridMapInfo}
          habitColor={habitColor}
          firstDayOfWeek={firstDayOfWeek}
        />
      )}
      {calendarHeatMapInfo?.length > 0 && filterCurrent.type === 'YEAR' && (
        <CalendarYearly calendarHeatMapInfo={calendarHeatMapInfo} habitColor={habit?.accentColor || ''} />
      )}
    </Box>
  );
};
export default memo(Calendar);
