import { getDayKey } from './common';
import dayjs from 'dayjs';
import { UnitSymbol } from 'models/common';
import { Habit, HabitGoal, HabitGoals } from 'models/habits';
import { getCurrentHabitGoal } from 'tools/habit-progress';
import { convert, getBaseUnitFromType, getType as newGetType } from 'tools/si-unit/si-unit-utils';
import {
  CheckInsStatus,
  getMonthKey,
  getWeekKey,
  HabitProgressInfo,
  IHabitLogFilter,
  isToday,
  IStreaks,
  SingleProgressBaseData,
  StreakProperties,
} from '.';
import { _dayjs } from 'tools/extended-dayjs';
import { BAD_HABIT_GOAL_VALUE } from 'models/habit-progress';
import { FirstWeekDay, HabitProgressMapInfo } from 'models/single-progress';

const WEEK_DAYS = 'weekDays';
const MONTH_DAYS = 'monthDays';
const DAY_INTERVAL = 'dayInterval';
const DAILY = 'daily';

type HabitRegularly = 'weekDays' | 'monthDays' | 'dayInterval' | ''

const IHabitRegularly: { [key: string]: HabitRegularly } = {
  [WEEK_DAYS]: 'weekDays',
  [MONTH_DAYS]: 'monthDays',
  [DAY_INTERVAL]: 'dayInterval'
}

const isValidCheckInSkip = (checkIn: number | null): boolean => {
  return checkIn === CheckInsStatus.SKIP;
};

const isValidHabitByDayInterval = (
  dayInterval: number,
  habitStartDate: number | null | undefined,
  currentDate: dayjs.Dayjs,
): boolean => {
  if (!dayInterval) return false;
  const diff = currentDate.diff(dayjs(habitStartDate).format('YYYY-MM-DD'), 'days');
  return diff % dayInterval === 0;
};

const isValidHabitByMonth = (currentDate: dayjs.Dayjs, monthDays: Map<string, string | number>): boolean => {
  if (!monthDays) return false;
  const day: string = currentDate.format('D');
  return monthDays.get(day) ? true : false;
};

const isValidHabitByWeek = (currentDate: dayjs.Dayjs, weekDays: Map<string, string | number>): boolean => {
  if (!weekDays) return false;
  const day: string = currentDate.locale('en').format('ddd').toLocaleLowerCase();
  return weekDays.get(day) ? true : false;
};

const isValidStreakByStatus = (subHabitProgressInfo: HabitProgressInfo): boolean => {
  const { checkInStatus } = subHabitProgressInfo;
  return checkInStatus === CheckInsStatus.COMPLETE || checkInStatus === CheckInsStatus.SKIP;
};

const isValidStreakByRegularly = (parameters: {
  habit: Habit,
  currentDate: dayjs.Dayjs,
  habitRegularlyCurrent: string,
  daysByRegularly: Map<string, string | number>
}): boolean => {
  const { habit, currentDate, habitRegularlyCurrent, daysByRegularly } = parameters;
  const habitStartDate: number | null | undefined = habit.startDate;
  const arrHabitRegularly: string[] = habit?.regularly?.split('-') as string[];
  let isPass = false;
  if (arrHabitRegularly?.length) {
    switch (habitRegularlyCurrent) {
      case DAY_INTERVAL:
        isPass = isValidHabitByDayInterval(Number(arrHabitRegularly[1]), habitStartDate, currentDate);
        break;
      case MONTH_DAYS:
        isPass = isValidHabitByMonth(currentDate, daysByRegularly);
        break;
      case WEEK_DAYS:
        isPass = isValidHabitByWeek(currentDate, daysByRegularly);
        break;
      default:
        break;
    }
  }
  return isPass;
};

const getStatusGoodHabitDailyByHabitLogMap = (habitLogValue: number, habitGoalValue: number): number => {
  if (habitLogValue >= habitGoalValue) {
    return CheckInsStatus.COMPLETE;
  }
  if (habitLogValue < habitGoalValue && habitLogValue > 0) {
    return CheckInsStatus.PROGRESS;
  }
  return CheckInsStatus.NONE;
};

const formatDateOrigin = (date: dayjs.Dayjs) => {
  return dayjs(date.format('YYYYMMDD'));
}

const getStatusBadHabitByHabitLogMap = (parameter: {
  habitLogValue: number;
  habitGoalValue: number;
  chooseDate: dayjs.Dayjs;
  habitGoalCurrent: HabitGoal | null | undefined;
  habitLogsMap: Map<string, IHabitLogFilter>;
  firstDayOfWeek: FirstWeekDay;
}): { checkInStatus: number, latestDayFailBadHabit: dayjs.Dayjs | null } => {
  // chooseDate same currentDate
  const { habitLogValue, habitGoalValue, chooseDate, habitGoalCurrent, habitLogsMap, firstDayOfWeek } = parameter;
  const habitGoalPeriodicity = habitGoalCurrent?.periodicity;
  if (habitGoalPeriodicity === 'daily') {
    if (habitLogValue > habitGoalValue) {
      return { checkInStatus: CheckInsStatus.FAIL, latestDayFailBadHabit: formatDateOrigin(chooseDate.add(1, 'days')) };
    }
    if (isToday(chooseDate) && habitLogValue && habitLogValue <= habitGoalValue) {
      return { checkInStatus: CheckInsStatus.PROGRESS, latestDayFailBadHabit: null }
    }
    if (isToday(chooseDate) && !habitLogValue) {
      return { checkInStatus: CheckInsStatus.NONE, latestDayFailBadHabit: null };
    }
    return { checkInStatus: CheckInsStatus.COMPLETE, latestDayFailBadHabit: null };
  }

  if (habitGoalPeriodicity === 'weekly' || habitGoalPeriodicity === 'monthly') {
    let fistDayOfPeriodicity = _dayjs();
    if (habitGoalPeriodicity === 'weekly') {
      fistDayOfPeriodicity = firstDayOfWeek === 'monday'
        ? fistDayOfPeriodicity = _dayjs(chooseDate).add(-1, 'day').startOf('week').add(1, 'day')
        : fistDayOfPeriodicity = _dayjs(chooseDate).startOf('week');
    } else {
      fistDayOfPeriodicity = _dayjs(chooseDate).startOf('month');
    }
    const checkInStatus = getBadHabitCheckInStatusByWeekAndMonth({
      fistDayOfPeriodicity,
      chooseDate,
      habitLogsMap,
      habitGoalValue,
    });
    if (checkInStatus === CheckInsStatus.FAIL) return { checkInStatus: CheckInsStatus.FAIL, latestDayFailBadHabit: formatDateOrigin(chooseDate.add(1, 'days')) };
    if (checkInStatus === CheckInsStatus.COMPLETE) return { checkInStatus: CheckInsStatus.COMPLETE, latestDayFailBadHabit: null };
    if (checkInStatus === CheckInsStatus.NONE) return { checkInStatus: CheckInsStatus.NONE, latestDayFailBadHabit: null };
  }
  const checkInStatus = dayjs().format('DDMMYYYY') === chooseDate.format('DDMMYYYY')
    ? CheckInsStatus.PROGRESS
    : CheckInsStatus.COMPLETE;
  return { checkInStatus, latestDayFailBadHabit: null }
};

const getBadHabitCheckInStatusByWeekAndMonth = (parameters: {
  fistDayOfPeriodicity: dayjs.Dayjs;
  chooseDate: dayjs.Dayjs;
  habitLogsMap: Map<string, IHabitLogFilter>;
  habitGoalValue: number;
}) => {
  const { fistDayOfPeriodicity, chooseDate, habitLogsMap, habitGoalValue } = parameters;
  let currentDate = chooseDate;
  let logValue = 0;
  let checkInStatus = isToday(chooseDate) ? CheckInsStatus.NONE : CheckInsStatus.COMPLETE;
  while (currentDate.valueOf() >= fistDayOfPeriodicity.valueOf()) {
    logValue += habitLogsMap.get(currentDate.format('DDMMYYYY'))?.logValue || 0;
    if (logValue > habitGoalValue) {
      checkInStatus = CheckInsStatus.FAIL;
      break;
    }
    currentDate = currentDate.subtract(1, 'days');
  }
  return checkInStatus;
};

const getHabitProgressInfo = (parameters: {
  habit: Habit,
  currentDate: dayjs.Dayjs,
  habitLogsMap: Map<string, IHabitLogFilter>,
  firstDayOfWeek: FirstWeekDay
  habitGoalCurrent: HabitGoal | null | undefined,
}): HabitProgressInfo => {
  const { habit, habitLogsMap, currentDate, habitGoalCurrent, firstDayOfWeek } = parameters;
  const dateSelectedConvert: string = currentDate.format('DDMMYYYY');
  const dateId: string = currentDate.format('YYYY-MM-DD');
  let checkInStatus: number = CheckInsStatus.NONE;
  let actualGoalValue: number | null = null;
  let actualSymbol: string | null = null;
  let goalValue: number | null = null;
  let actualCheckInStatus: number | null = null;
  let actualNoGoalValue: number | null = null;
  let latestDayFailBadHabit: dayjs.Dayjs | null = null;
  const habitType = habit.habitType?.habitType;

  if (habitLogsMap && (habit.goal || habit.goals) && habitGoalCurrent) {
    const habitPeriodicity = habitGoalCurrent?.periodicity || '';
    const habitSymbolCurrent = habitGoalCurrent?.unit?.symbol as UnitSymbol;
    const goalValueCurrent: number = habitGoalCurrent?.value || 0;
    const listHabitLog: Map<string, IHabitLogFilter> = habitLogsMap;
    const unitSymbol = listHabitLog.get(dateSelectedConvert)?.habitLog?.unitSymbol;
    const habitLogValue = listHabitLog.get(dateSelectedConvert)?.logValue || 0;
    const baseUnitSymbolOfHabit = getBaseUnitFromType({
      type: newGetType({ unitSymbol: habitSymbolCurrent }),
    });
    const habitGoalValue: number = convert({
      source: habitSymbolCurrent,
      target: baseUnitSymbolOfHabit,
      value: goalValueCurrent,
    });
    actualGoalValue = habitLogValue;
    goalValue = habitGoalValue;
    actualSymbol = unitSymbol as string;
    if (unitSymbol && habitType !== 'bad') {
      if (habitPeriodicity === DAILY) {
        checkInStatus = getStatusGoodHabitDailyByHabitLogMap(habitLogValue, habitGoalValue);
      }
      if (habitPeriodicity !== DAILY && listHabitLog.get(dateSelectedConvert)?.logValue) {
        checkInStatus = CheckInsStatus.COMPLETE;
      }
    }

    if (habitType === 'bad' && habitGoalCurrent) {
      const habitGoalValue: number = convert({
        source: habitSymbolCurrent,
        target: baseUnitSymbolOfHabit,
        value: goalValueCurrent,
      });
      const { checkInStatus: _checkInStatus, latestDayFailBadHabit: _latestDayFailBadHabit } = getStatusBadHabitByHabitLogMap({
        habitLogValue,
        habitGoalValue,
        chooseDate: currentDate,
        habitGoalCurrent,
        habitLogsMap,
        firstDayOfWeek
      });
      checkInStatus = _checkInStatus;
      latestDayFailBadHabit = _latestDayFailBadHabit
    }
  }

  if (habitLogsMap && !habitGoalCurrent) {
    const habitLog = habitLogsMap.get(dateSelectedConvert);
    if (habitLog && habitLog.isNoGoal) {
      actualNoGoalValue = habitLog.logValue;
    }
  }

  // handle habit have checkIns
  if (habit.checkins && habitType !== 'bad') {
    const checkIn = habit.checkins[dateSelectedConvert];
    if (checkIn) {
      actualCheckInStatus = checkIn.rawValue;
    }
    if (checkIn && checkIn !== CheckInsStatus.NONE) {
      checkInStatus = habit.checkins[dateSelectedConvert].rawValue;
    }
  }

  if (habitType === 'bad') {
    if (habit.checkins && habit.checkins[dateSelectedConvert]) {
      if (habit.checkins[dateSelectedConvert].rawValue === CheckInsStatus.FAIL) {
        latestDayFailBadHabit = formatDateOrigin(currentDate.add(1, 'days'));
      }
      checkInStatus = habit.checkins[dateSelectedConvert].rawValue;
    }

    if (checkInStatus === CheckInsStatus.NONE && !isToday(currentDate)) {
      checkInStatus = CheckInsStatus.COMPLETE;
    }

    if (checkInStatus === CheckInsStatus.NONE && isToday(currentDate)) {
      checkInStatus = CheckInsStatus.NONE;
    }
  }

  return {
    dateId,
    checkInStatus,
    actualGoalValue,
    goalValue,
    habitGoalCurrent,
    actualSymbol,
    actualCheckInStatus,
    actualNoGoalValue,
    latestDayFailBadHabit
  };
};

const setHabitProgressInfo = (parameters: {
  habit: Habit,
  habitLogsMap: Map<string, IHabitLogFilter>,
  currentDate: dayjs.Dayjs,
  firstDayOfWeek: FirstWeekDay,
  habitProgressInfo: HabitProgressMapInfo,
  subHabitProgressInfo: HabitProgressInfo,
}) => {
  const { subHabitProgressInfo, habitProgressInfo, currentDate, habit, habitLogsMap, firstDayOfWeek } = parameters
  const {
    dateId,
    checkInStatus,
    actualGoalValue,
    goalValue,
    habitGoalCurrent,
    actualSymbol,
    actualCheckInStatus,
    actualNoGoalValue
  } = subHabitProgressInfo;
  const weekKey: string = getWeekKey(currentDate, firstDayOfWeek);
  const monthKey: string = getMonthKey(currentDate);

  // set symbol current by goal current
  if (!habitProgressInfo?.symbolDefault) habitProgressInfo.symbolDefault = habitGoalCurrent?.unit?.symbol as UnitSymbol;

  // set data for card overview
  if (habit?.habitType && habit?.habitType?.habitType === 'bad' && !actualGoalValue && !isToday(currentDate)) {
    if (habitGoalCurrent?.value) {
      const numberOfZeroDay = (habitProgressInfo.dateToBadHabitZeroDayMap?.get(monthKey) || 0) + 1;
      habitProgressInfo.dateToBadHabitZeroDayMap?.set(monthKey, numberOfZeroDay);
    }
  }

  setLatestDayFailBadHabit({
    habit,
    currentDate,
    checkInStatus,
    habitGoalCurrent,
    habitProgressInfo
  })

  setHabitNumberOfCompletedMap({
    habitGoalCurrent,
    habitLogsMap,
    chooseDate: currentDate,
    checkInStatus,
    monthKey,
    habitProgressInfo,
    habit,
    firstDayOfWeek,
  });
  setHabitNumberLogValueByMonthMap({
    habit,
    actualGoalValue,
    habitGoalCurrent,
    checkInStatus,
    habitProgressInfo,
    dateKey: { monthKey, dateId },
  });
  if (checkInStatus === CheckInsStatus.SKIP) {
    const numberOfSkipped = (habitProgressInfo.dateToNumberOfSkippedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfSkippedMonthMap?.set(monthKey, numberOfSkipped);
  }
  if (checkInStatus === CheckInsStatus.FAIL) {
    const numberOfFailed = (habitProgressInfo.dateToNumberOfFailedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfFailedMonthMap?.set(monthKey, numberOfFailed);
  }

  // set symbol -> daily | weekly | monthly
  setHabitActualSymbolMap({
    habitProgressInfo,
    actualSymbol,
    dateKey: { dayKey: dateId, weekKey, monthKey },
  });

  // set status check-in
  habitProgressInfo.dateToCheckInStatusMap?.set(dateId, checkInStatus);
  habitProgressInfo.dateToActualCheckInStatus?.set(dateId, actualCheckInStatus);
  setHabitNumberOfCheckInStatusByWeekAndMonthMap({
    habitProgressInfo,
    actualCheckInStatus,
    dateKey: { weekKey, monthKey },
  });
  setHabitActualNoGoalValueMap({
    habitProgressInfo,
    dateKey: { dayKey: dateId, weekKey, monthKey },
    actualNoGoalValue,
  });
  setHabitLogValueByWeekAndMonthMap({
    habitProgressInfo,
    actualGoalValue,
    dateKey: { weekKey, monthKey },
  });

  // set goal -> daily | weekly | monthly
  habitProgressInfo.dateToActualGoalValueMap?.set(dateId, actualGoalValue);
  habitProgressInfo.dateToHabitGoalCurrentMap?.set(dateId, habitGoalCurrent);
  habitProgressInfo.dateToGoalValueMap?.set(dateId, goalValue);
  if (habitGoalCurrent?.periodicity === 'weekly') habitProgressInfo.dateToGoalWeekMap?.set(weekKey, habitGoalCurrent);
  if (habitGoalCurrent?.periodicity === 'monthly')
    habitProgressInfo.dateToGoalMonthMap?.set(monthKey, habitGoalCurrent);
};

const setLatestDayFailBadHabit = (parameters: {
  habit: Habit,
  currentDate: dayjs.Dayjs,
  checkInStatus: number,
  habitGoalCurrent: HabitGoal | null | undefined,
  habitProgressInfo: HabitProgressMapInfo,
}) => {
  const { habit, habitProgressInfo, currentDate, checkInStatus, habitGoalCurrent } = parameters;
  const isQuitGoal = habitGoalCurrent?.value === BAD_HABIT_GOAL_VALUE.QUIT;
  if (habit?.habitType?.habitType === 'bad' && isQuitGoal) {
    const latestDay = habitProgressInfo.latestDayFailBadHabit?.latestDay;
    const isUpdateValue = habitProgressInfo.latestDayFailBadHabit?.isUpdateValue;
    if (!isUpdateValue) { return }

    if (!latestDay) {
      habitProgressInfo.latestDayFailBadHabit = {
        latestDay: dayjs(habit.startDate),
        isUpdateValue: true
      }
    }

    if (checkInStatus === CheckInsStatus.SKIP && latestDay) {
      habitProgressInfo.latestDayFailBadHabit = {
        latestDay: latestDay.add(1, 'days'),
        isUpdateValue: true
      };
    }

    if (checkInStatus === CheckInsStatus.FAIL && latestDay) {
      const diff = dayjs(latestDay).diff(habit.startDate, 'day', true);
      const days = Math.floor(diff); // number skipped
      habitProgressInfo.latestDayFailBadHabit = {
        latestDay: formatDateOrigin(currentDate).add((days + 1), 'days'), // days + 1 : numberSkipped + theDayFailed
        isUpdateValue: false
      }
    }
  }
}

const setHabitNumberLogValueByMonthMap = (parameters: {
  habit: Habit;
  actualGoalValue: number | null;
  habitGoalCurrent: HabitGoal | null | undefined;
  checkInStatus: number;
  habitProgressInfo: HabitProgressMapInfo;
  dateKey: { monthKey: string; dateId: string };
}) => {
  const {
    habit,
    actualGoalValue,
    habitGoalCurrent,
    checkInStatus,
    habitProgressInfo,
    dateKey: { monthKey },
  } = parameters;
  if (actualGoalValue || (!habitGoalCurrent && checkInStatus === CheckInsStatus.COMPLETE)) {
    const isBabHabit = habit?.habitType?.habitType !== 'bad';
    const nowSymbol = habitProgressInfo.symbolDefault;
    const _actualLogValue =
      (isBabHabit && nowSymbol === 'rep') || (!habitGoalCurrent && !nowSymbol)
        ? actualGoalValue || 1
        : actualGoalValue || 0;
    const logValueFromMap: number | null | undefined = habitProgressInfo.dateToNumberLogValueMonthMap?.get(monthKey);
    const logValue = logValueFromMap ? logValueFromMap + _actualLogValue : _actualLogValue || 0;
    habitProgressInfo.dateToNumberLogValueMonthMap?.set(monthKey, logValue);
  }
};

const setHabitNumberOfCompletedMap = (parameters: {
  habitGoalCurrent: HabitGoal | null | undefined;
  habitLogsMap: Map<string, IHabitLogFilter>;
  chooseDate: dayjs.Dayjs;
  checkInStatus: number;
  monthKey: string;
  habitProgressInfo: HabitProgressMapInfo;
  habit: Habit;
  firstDayOfWeek: FirstWeekDay;
}) => {
  const {
    habitLogsMap,
    checkInStatus,
    monthKey,
    habitProgressInfo,
    habit,
    habitGoalCurrent,
    chooseDate,
    firstDayOfWeek,
  } = parameters;
  if (habit?.habitType?.habitType === 'bad' && checkInStatus === CheckInsStatus.COMPLETE) {
    const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
  }
  if (habit?.habitType?.habitType !== 'bad') {
    // let numberOfCompleted = 0;
    const habitGoalPer = habitGoalCurrent?.periodicity;
    const isGoalPer = habitGoalPer === 'weekly' || habitGoalPer === 'monthly';
    if (isGoalPer) {
      const habitSymbolCurrent = habitGoalCurrent?.unit?.symbol as UnitSymbol;
      const baseUnitSymbolOfHabit = getBaseUnitFromType({
        type: newGetType({ unitSymbol: habitSymbolCurrent }),
      });

      const goalValue = convert({
        source: habitSymbolCurrent,
        target: baseUnitSymbolOfHabit,
        value: habitGoalCurrent?.value || 0,
      });
      const logValue =
        habitGoalPer === 'weekly'
          ? habitLogsMap?.get(getWeekKey(chooseDate, firstDayOfWeek))?.logValue || 0
          : habitLogsMap?.get(getMonthKey(chooseDate))?.logValue || 0;

      if (logValue >= goalValue && checkInStatus !== CheckInsStatus.FAIL && checkInStatus !== CheckInsStatus.SKIP) {
        const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
        habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
      }
    }

    if (!isGoalPer && checkInStatus === CheckInsStatus.COMPLETE) {
      const numberOfCompleted = (habitProgressInfo.dateToNumberOfCompletedMonthMap?.get(monthKey) || 0) + 1;
      habitProgressInfo.dateToNumberOfCompletedMonthMap?.set(monthKey, numberOfCompleted);
    }
  }
};

const setHabitActualNoGoalValueMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  dateKey: {
    dayKey: string;
    weekKey: string;
    monthKey: string;
  };
  actualNoGoalValue: number | null;
}) => {
  const {
    habitProgressInfo,
    dateKey: { dayKey, weekKey, monthKey },
    actualNoGoalValue,
  } = parameters;
  if (actualNoGoalValue) {
    habitProgressInfo.dateToActualNoGoalValueMap?.set(dayKey, actualNoGoalValue);
    const weekValue: number | null | undefined = habitProgressInfo.dateToActualNoGoalValueMap?.get(weekKey);
    weekValue
      ? habitProgressInfo.dateToActualNoGoalValueMap?.set(weekKey, actualNoGoalValue + weekValue)
      : habitProgressInfo.dateToActualNoGoalValueMap?.set(weekKey, actualNoGoalValue);
    const monthValue: number | null | undefined = habitProgressInfo.dateToActualNoGoalValueMap?.get(monthKey);
    monthValue
      ? habitProgressInfo.dateToActualNoGoalValueMap?.set(monthKey, actualNoGoalValue + monthValue)
      : habitProgressInfo.dateToActualNoGoalValueMap?.set(monthKey, actualNoGoalValue);
  }
};

const setHabitLogValueByWeekAndMonthMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualGoalValue: number | null;
  dateKey: {
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualGoalValue,
    dateKey: { weekKey, monthKey },
  } = parameters;
  if (actualGoalValue) {
    const logValueWeek: number | null | undefined = habitProgressInfo.dateToLogValueWeekMap?.get(weekKey);
    const logValueWeekCurrent = logValueWeek ? logValueWeek + actualGoalValue : actualGoalValue || 0;
    const logValueMonth: number | null | undefined = habitProgressInfo.dateToLogValueMonthMap?.get(monthKey);
    const logValueMonthCurrent = logValueMonth ? logValueMonth + actualGoalValue : actualGoalValue || 0;
    habitProgressInfo.dateToLogValueWeekMap?.set(weekKey, logValueWeekCurrent);
    habitProgressInfo.dateToLogValueMonthMap?.set(monthKey, logValueMonthCurrent);
  }
};

const setHabitNumberOfCheckInStatusByWeekAndMonthMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualCheckInStatus: number | null;
  dateKey: {
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualCheckInStatus,
    dateKey: { weekKey, monthKey },
  } = parameters;
  if (actualCheckInStatus && actualCheckInStatus === CheckInsStatus.COMPLETE) {
    const numberOfCheckInWeek: number = (habitProgressInfo.dateToNumberOfCheckInStatusWeek?.get(weekKey) || 0) + 1;
    const numberOfCheckInMonth: number = (habitProgressInfo.dateToNumberOfCheckInStatusMonth?.get(monthKey) || 0) + 1;
    habitProgressInfo.dateToNumberOfCheckInStatusWeek?.set(weekKey, numberOfCheckInWeek);
    habitProgressInfo.dateToNumberOfCheckInStatusMonth?.set(monthKey, numberOfCheckInMonth);
  }
};

const setHabitActualSymbolMap = (parameters: {
  habitProgressInfo: HabitProgressMapInfo;
  actualSymbol: string | null;
  dateKey: {
    dayKey: string;
    weekKey: string;
    monthKey: string;
  };
}) => {
  const {
    habitProgressInfo,
    actualSymbol,
    dateKey: { dayKey, weekKey, monthKey },
  } = parameters;
  habitProgressInfo.dateToActualSymbolMap?.set(dayKey, actualSymbol);
  if (!habitProgressInfo.dateToActualSymbolMap?.has(weekKey) && actualSymbol) {
    habitProgressInfo.dateToActualSymbolMap?.set(weekKey, actualSymbol);
  }
  if (!habitProgressInfo.dateToActualSymbolMap?.has(monthKey) && actualSymbol) {
    habitProgressInfo.dateToActualSymbolMap?.set(monthKey, actualSymbol);
  }
};

const setConditionToCurrentStreakByRegularly = (
  isRegularly: boolean,
  chooseDate: dayjs.Dayjs,
  habitProgressInfo: HabitProgressMapInfo,
) => {
  const isBreakStreak = !habitProgressInfo.dateToConditionIsCurrentStreakMap || habitProgressInfo.dateToConditionIsCurrentStreakMap?.size > 1;
  if (isBreakStreak) return
  if (isToday(chooseDate)) {
    const isFail = habitProgressInfo.dateToCheckInStatusMap?.get(chooseDate.format('YYYY-MM-DD')) === CheckInsStatus.FAIL;
    habitProgressInfo.dateToConditionIsCurrentStreakMap?.set(
      chooseDate.format('YYYY-MM-DD'),
      isRegularly && !isFail ? true : false,
    );
  }
  if (!isToday(chooseDate) && isRegularly) {
    habitProgressInfo.dateToConditionIsCurrentStreakMap?.set(chooseDate.format('YYYY-MM-DD'), true);
  }
};

const setConditionToCurrentStreakNormal = (chooseDate: dayjs.Dayjs, habitProgressInfo: HabitProgressMapInfo) => {
  const isBreakStreak = !habitProgressInfo.dateToConditionIsCurrentStreakMap || habitProgressInfo.dateToConditionIsCurrentStreakMap.size > 1;
  if (isBreakStreak) return;
  const isFail = habitProgressInfo.dateToCheckInStatusMap?.get(chooseDate.format('YYYY-MM-DD')) === CheckInsStatus.FAIL;
  habitProgressInfo?.dateToConditionIsCurrentStreakMap?.set(
    chooseDate.format('YYYY-MM-DD'),
    isFail && isToday(chooseDate) ? false : true,
  );
};

const setDateToConditionDisplayHabitByRegularly = (habitProgressInfo: HabitProgressMapInfo, key: string, value: boolean) => {
  habitProgressInfo?.dateToConditionDisplayHabitByRegularly?.set(key, value);
}

const init = (habit: Habit): HabitProgressMapInfo => {
  return {
    habitStartDate: habit?.startDate,
    logInfoType: habit.logInfo?.type,
    symbolDefault: '',
    latestDayFailBadHabit: { latestDay: null, isUpdateValue: true },
    dateToCheckInStatusMap: new Map(),
    dateToActualGoalValueMap: new Map(),
    dateToActualNoGoalValueMap: new Map(),
    dateToGoalValueMap: new Map(),
    dateToHabitGoalCurrentMap: new Map(),
    dateToActualSymbolMap: new Map(),
    dateToLogValueWeekMap: new Map(),
    dateToLogValueMonthMap: new Map(),
    dateToActualCheckInStatus: new Map(),
    dateToNumberOfCheckInStatusWeek: new Map(),
    dateToNumberOfCheckInStatusMonth: new Map(),
    dateToGoalWeekMap: new Map(),
    dateToGoalMonthMap: new Map(),
    dateToNumberOfCompletedMonthMap: new Map(),
    dateToNumberOfSkippedMonthMap: new Map(),
    dateToNumberOfFailedMonthMap: new Map(),
    dateToLogValueYearMap: new Map(),
    dateToConditionIsCurrentStreakMap: new Map(),
    dateToBadHabitZeroDayMap: new Map(),
    dateToNumberLogValueMonthMap: new Map(),
    dateToConditionDisplayHabitByRegularly: new Map(),
  }
}

const getListDayByRegularly = (habit: Habit): Map<string, number | string> => {
  const days: Map<string, number | string> = new Map();
  const regularly = habit?.regularly?.split('-') as string[];
  const dayByRegularly: string[] = regularly[1]?.split(',');
  dayByRegularly.forEach((element: string | number) => {
    days.set(String(element), element);
  });
  return days;
};

const getGoal = (parameters: { goals: HabitGoals, date: dayjs.Dayjs, firstDayOfWeek: FirstWeekDay }) => {
  const { goals, date, firstDayOfWeek } = parameters;
  return Object.keys(goals).length
    ? getCurrentHabitGoal({ goals, date, firstDayOfWeek })
    : null;
}

const getHabitRegularly = (habit: Habit): HabitRegularly => {
  if(habit?.regularly?.includes(WEEK_DAYS)) return 'weekDays';
  if(habit?.regularly?.includes(MONTH_DAYS)) return 'monthDays';
  if(habit?.regularly?.includes(DAY_INTERVAL)) return 'dayInterval';
  return ''
}

const handleStreakProperties = (
  habit: Habit,
  currentDate: dayjs.Dayjs,
  isValidStreak: boolean,
  isValidStreakByRegularly: boolean,
): StreakProperties | null => {
  if (!habit) return null;
  const checkInStatus =
    habit.checkins && habit.checkins[currentDate.format('DDMMYYYY')]
      ? habit.checkins[currentDate.format('DDMMYYYY')].rawValue
      : null;
  const isSkip: boolean = isValidCheckInSkip(checkInStatus);
  let subStartDate: string | null = null,
    subEndDate: string | null = null,
    subCount = 0;
  if (habit.regularly === DAILY || !habit.regularly) {
    if (!subEndDate) subEndDate = currentDate.format('YYYY/MM/DD');
    if (!isSkip) subCount = 1;
    subStartDate = currentDate.format('YYYY/MM/DD');
    return { subStartDate, subCount, subEndDate };
  }
  if (!subEndDate && isValidStreak && isValidStreakByRegularly) subEndDate = currentDate.format('YYYY/MM/DD');
  if (!isSkip && isValidStreak && isValidStreakByRegularly) subCount = 1;
  if (isValidStreak && isValidStreakByRegularly) subStartDate = currentDate.format('YYYY/MM/DD');
  return { subStartDate, subCount, subEndDate };
};

const handleHabitByRegularly = (parameters: {
  habit: Habit,
  currentDate: dayjs.Dayjs,
  isValidStreakStatus: boolean,
  daysByRegularly: Map<string, string | number>,
  habitRegularlyCurrent: "weekDays" | "monthDays" | "dayInterval" | ""
}): { isStreaks: boolean, isStreakByRegularly: boolean, streakPropertiesComputed: StreakProperties | null } => {
  const { habit, currentDate, isValidStreakStatus, daysByRegularly, habitRegularlyCurrent } = parameters;
  const isStreakByRegularly = isValidStreakByRegularly({
    habit,
    currentDate,
    habitRegularlyCurrent,
    daysByRegularly
  })
  const isStreaks = !(!isValidStreakStatus && isStreakByRegularly)
  const streakPropertiesComputed = isStreaks ? handleStreakProperties(habit, currentDate, isValidStreakStatus, isStreakByRegularly) : null
  return { isStreaks, isStreakByRegularly, streakPropertiesComputed }
}

export const getSingleBaseData = (
  habit: Habit,
  habitLogsMap: Map<string, IHabitLogFilter>,
  listHabitGoal: HabitGoals,
  isCurrentStreak: boolean,
  firstDayOfWeek: FirstWeekDay,
): SingleProgressBaseData => {
  try {
    const habitStartDate: number | null | undefined = habit.startDate,
      habitRegularly: string | null | undefined = getHabitRegularly(habit),
      arrHabitStreaks: IStreaks[] = [],
      formatHabitStartDate = dayjs(dayjs(habitStartDate).format('YYYY-MM-DD')),
      habitProgressInfo: HabitProgressMapInfo = init(habit);
    let currentDate: dayjs.Dayjs = dayjs(),
      count = 0,
      startDate: string | null = null,
      endDate: string | null = null,
      isStreaks = false,
      isPerfectStreak = false,
      isValidStreakStatus = false,
      streakProperties: StreakProperties | null = null,
      daysByRegularly: Map<string, string | number> = new Map();

    if (IHabitRegularly[habitRegularly] === 'monthDays') daysByRegularly = getListDayByRegularly(habit);
    if (IHabitRegularly[habitRegularly] === 'weekDays') daysByRegularly = getListDayByRegularly(habit);

    while (true) {
      // break loop
      if (formatHabitStartDate.isAfter(currentDate) || (isCurrentStreak && arrHabitStreaks.length > 1)) {
        break;
      };
      // executed
      const _habitGoalCurrent = getGoal({ goals: listHabitGoal, date: currentDate, firstDayOfWeek });
      const subHabitProgressInfo: HabitProgressInfo = getHabitProgressInfo({
        habit,
        currentDate,
        habitLogsMap,
        firstDayOfWeek,
        habitGoalCurrent: _habitGoalCurrent,
      });
      setHabitProgressInfo({
        habit,
        habitLogsMap,
        currentDate,
        firstDayOfWeek,
        habitProgressInfo,
        subHabitProgressInfo
      });
      isValidStreakStatus = isValidStreakByStatus(subHabitProgressInfo);

      //handle habit by regularly
      if (!!IHabitRegularly[habitRegularly]) {
        const _habitRegularly = IHabitRegularly[habitRegularly];
        const { isStreaks: _isStreaks, isStreakByRegularly, streakPropertiesComputed } = handleHabitByRegularly({
          habit,
          currentDate,
          isValidStreakStatus,
          daysByRegularly,
          habitRegularlyCurrent: _habitRegularly
        });
        isStreaks = _isStreaks
        streakProperties = streakPropertiesComputed
        setDateToConditionDisplayHabitByRegularly(habitProgressInfo, getDayKey(currentDate), isStreakByRegularly)
        setConditionToCurrentStreakByRegularly(isStreakByRegularly, currentDate, habitProgressInfo);
      } else {
        isStreaks = isValidStreakStatus;
        if (isStreaks) streakProperties = handleStreakProperties(habit, currentDate, isValidStreakStatus, false);
        habitProgressInfo?.dateToConditionDisplayHabitByRegularly?.set(getDayKey(currentDate), true);
        setConditionToCurrentStreakNormal(currentDate, habitProgressInfo);
      }

      // set consecutive series streak
      if (isStreaks || currentDate.format('DDMMYYYY') === dayjs().format('DDMMYYYY')) {
        if (isStreaks && streakProperties) {
          const { subStartDate, subCount, subEndDate } = streakProperties;
          if (!endDate && subEndDate) endDate = subEndDate;
          count += subCount;
          if (subStartDate) startDate = subStartDate;
        } else {
          const checkInStatus = subHabitProgressInfo.checkInStatus;
          endDate =
            isToday(currentDate) && checkInStatus === CheckInsStatus.FAIL
              ? dayjs().subtract(1, 'day').format('YYYY/MM/DD')
              : dayjs().format('YYYY/MM/DD');
          count = 0;
          startDate = endDate;
        }
        isPerfectStreak = true;
      } else {
        if (count > 0) arrHabitStreaks.push({ count, startDate, endDate });
        count = 0;
        startDate = null;
        endDate = null;
        isPerfectStreak = false;
      }
      // minus a day
      currentDate = currentDate.subtract(1, 'days');
    }
    // case just have one consecutive series streak
    if (isPerfectStreak && count > 0) arrHabitStreaks.push({ count, startDate, endDate });

    // result
    return {
      streaks: arrHabitStreaks,
      habitProgressInfo,
    };
  } catch (error) {
    throw new Error(error as string | undefined);
  }
};
