interface IFontSizeValue {
  FONT_S9: string;
  FONT_S11: string;
  FONT_S12: string;
  FONT_S13: string;
  FONT_S14: string;
  FONT_S15: string;
  FONT_S16: string;
  FONT_S17: string;
  FONT_S18: string;
  FONT_S19: string;
  FONT_S21: string;
  FONT_S22: string;
  FONT_S23: string;
  FONT_S26: string;
  FONT_S27: string;
  FONT_S29: string;
  FONT_S31: string;
  FONT_S34: string;
  FONT_S37: string;
  FONT_S45: string;
}

const fontSize: IFontSizeValue = {
  FONT_S9: '0.5625rem',
  FONT_S11: '0.6875rem',
  FONT_S12: '0.75rem',
  FONT_S13: '0.8125rem',
  FONT_S14: '0.875rem',
  FONT_S15: '0.9375rem',
  FONT_S16: '1rem',
  FONT_S17: '1.0625rem',
  FONT_S18: '1.125rem',
  FONT_S19: '1.1875rem',
  FONT_S21: '1.3125rem',
  FONT_S22: '1.375rem',
  FONT_S23: '1.4375rem',
  FONT_S26: '1.625rem',
  FONT_S27: '1.6875rem',
  FONT_S29: '1.8125rem',
  FONT_S31: '1.9375rem',
  FONT_S34: '2.125rem',
  FONT_S37: '2.466rem',
  FONT_S45: '2.8125rem',
};

export { fontSize };
