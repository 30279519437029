import { Box, Flex, Text } from '@chakra-ui/layout';
import dayjs from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import { IStreaks } from 'models/single-progress';
import React, { memo, ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import HabitStreakItem from './HabitStreakItem';
import HabitStreakItemEmpty from './habit_streak_item_empty_single';

interface HabitStreaksProps {
  streaks: IStreaks[];
  habitColor: string | null | undefined;
}

const HabitStreaks: React.FC<HabitStreaksProps> = ({ streaks, habitColor }: HabitStreaksProps): ReactElement => {
  const { t } = useTranslation();
  const location = useLocation();
  const { typography, colorScheme } = useThemeData();

  const maxStreakCount = streaks.sort((s1: IStreaks, s2: IStreaks) => s2.count - s1.count)[0]?.count;
  return (
    <Box
      mb="12px"
      display={!!location?.search ? 'flex' : 'block'}
      border={`1px solid ${colorScheme.platform.border}`}
      p="18px 16px 27px 16px"
      borderRadius="10px"
      overflow="hidden"
      backgroundColor={colorScheme.background.primary[1]}
    >
      <Box width={'100%'}>
        <Flex alignItems="center" mb="22px">
          <Text mt="6px" ml="10px" {...typography.other.heading[5]} color={colorScheme.label.primary}>
            {t('progress.streaks')}
          </Text>
        </Flex>
        <Box p={streaks?.length ? '0 60px' : '0 30px'}>
          {streaks?.length ? (
            streaks
              .sort((streak1: IStreaks, streak2: IStreaks) => {
                return dayjs(String(streak1.startDate)).valueOf() - dayjs(String(streak2.startDate)).valueOf();
              })
              .map((streak: IStreaks, index: number) => {
                return (
                  <HabitStreakItem
                    streak={streak}
                    maxStreakCount={maxStreakCount}
                    habitColor={habitColor}
                    key={index}
                  />
                );
              })
          ) : (
            <HabitStreakItemEmpty />
          )}
        </Box>
      </Box>
    </Box>
  );
};
export default memo(HabitStreaks);
