import { Button, ButtonProps, forwardRef } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React from 'react';
import Icon from '../Icon';
interface PushDownButtonProps extends ButtonProps {
  leftIcon: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  rightIconColor: string;
  title: string;
  minimize?: boolean;
}

const PullDownButton = forwardRef<PushDownButtonProps, 'button'>(
  ({ leftIcon, title, rightIconColor, minimize, ...rest }: PushDownButtonProps, ref) => {
    const { typography } = useThemeData();
    return (
      <Button
        {...rest}
        ref={ref}
        height="30px"
        pl="10px"
        pr="10px"
        {...typography.emphasized.callout}
        borderRadius="5px"
        cursor="default"
        leftIcon={leftIcon}
        lineHeight="30px"
        rightIcon={minimize ? <></> : <Icon name="ic_arrow_down" width={16} height={16} fill={rightIconColor} />}
        className={minimize ? 'pulldown-button' : ''}
        outline="unset"
        _focus={{ outline: 'none' }}
      >
        {minimize ? null : title}
      </Button>
    );
  },
);
export default PullDownButton;
