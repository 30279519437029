type UniversalColor = {
  universal: {
    black: string;
    white: string;
  };
  icon: {
    teal: string;
    lapis: string;
    parakeet: string;
    pumpkin: string;
    candy: string;
    royal: string;
  };
};

type ModeColor = {
  accent: {
    primary: string;
  };
  label: {
    primary: string;
    secondary: string;
  };
  background: {
    primary: {
      1: string;
      2: string;
      3: string;
      4: string;
    };
    grouped: {
      1: string;
      2: string;
    };
    elevated: {
      1: string;
      2: string;
    };
    other: {
      1: string
    }
    backdrop: string;
  };
  actions: {
    complete: string;
    skip: string;
    fail: string;
  };
  supporting: {
    seperator: string;
    streaks: string;
    success: string;
    error: string;
    premium: string;
  };
  platform: {
    menu: string;
    control: {
      background: string;
      destructive: string;
      accessory: string;
    };
    border: string;
    backdrop: string;
  };
  other: {
    border: string;
    pushdownHover: string;
    menuHeading: string;
    menuItem: string;
    backgroundSpinner: string;
    placeholder: string;
    bgProfileNav: string;
    bgProfileContent: string
  };
  sidebar: {
    background: string;
    backgroundAvatar: string;
    item: {
      selected: string;
      hover: string;
      colorHover: string;
      backgroundFocus: string;
      borderColorFocus: string;
      backgroundHighlight: string;
      borderColorHighlight: string;
      backgroundOptions: string;
    };
  };
};

export type ColorScheme = UniversalColor & ModeColor;

const universalColors: UniversalColor = {
  universal: {
    black: "#000000",
    white: "#FFFFFF",
  },
  icon: {
    teal: "#2AA8D0",
    lapis: "#2A67F4",
    parakeet: "#299240",
    pumpkin: "#E0861D",
    candy: "#D32C2C",
    royal: "#992AC0",
  },
};

export const lightColorScheme: ColorScheme = {
  ...universalColors,
  accent: {
    primary: "#2A67F4",
  },
  label: {
    primary: "#000000",
    secondary: "#7B7C7C",
  },
  background: {
    primary: {
      1: "#FFFEFF",
      2: "#F5F5F5",
      3: "#ECECEC",
      4: "#F5F5F5",
    },
    backdrop: "#00000026",
    elevated: {
      1: "#FFFFFF",
      2: "#FFFFFF",
    },
    grouped: {
      1: "#F5F5F5",
      2: "#FFFFFF",
    },
    other: {
      1: '#7b7c7c1a'
    }
  },
  actions: {
    complete: "#2A67F4",
    fail: "#BCCAED",
    skip: "#000000",
  },
  supporting: {
    seperator: "#E5E4E5",
    error: "#D23737",
    premium: "#F1A121",
    streaks: "#DC6215",
    success: "#0FAF09",
  },
  platform: {
    menu: "#FCFCFC",
    control: {
      background: "#FFFFFF",
      destructive: "#D23737",
      accessory: "#E5E4E5",
    },
    border: "#E5E4E5",
    backdrop: "#C6C6C68C",
  },
  other: {
    border: "#E5E4E5",
    pushdownHover: "#2458D1",
    menuHeading: "#2A67F4",
    menuItem: "rgba(0, 0, 0, 0.7)",
    backgroundSpinner: '#7B7C7C',
    placeholder: "#0000004d",
    bgProfileNav: '#F5F5F5',
    bgProfileContent: '#FFFEFF'
  },
  sidebar: {
    background: "#F5F5F5",
    backgroundAvatar: "#ECECEC",
    item: {
      selected: "#2A67F4",
      hover: "#ECECEC",
      colorHover: "#ffffff",
      backgroundFocus: "#FFFFFF",
      borderColorFocus: "#2A67F4",
      backgroundHighlight: "#FFFFFF",
      borderColorHighlight: "#2A67F4",
      backgroundOptions: "#FCFCFC",
    },
  },
};

export const darkColorScheme: ColorScheme = {
  ...universalColors,
  accent: {
    primary: "#3662CC",
  },
  label: {
    primary: "#FFFFFFF2",
    secondary: "#7B7C7C",
  },
  background: {
    primary: {
      1: "#171717",
      2: "#323232",
      3: "#3C3C3C",
      4: "#292829",
    },
    backdrop: "#00000080",
    elevated: {
      1: "#323232",
      2: "#414141",
    },
    grouped: {
      1: "#171717",
      2: "#323232",
    },
    other: {
      1: '#7b7c7c1a'
    }
  },
  actions: {
    complete: "#3662CC",
    fail: "#A1ADCB",
    skip: "#C1C1C1",
  },
  supporting: {
    seperator: "#292829",
    error: "#B94646",
    premium: "#E9A02C",
    streaks: "#DC6215",
    success: "#2C9328",
  },
  platform: {
    menu: "#333333",
    control: {
      background: "#424242",
      destructive: "#FF5156",
      accessory: "#636363",
    },
    border: "#4A4A4A",
    backdrop: "#191919BF",
  },
  other: {
    border: "#4A4A4A",
    pushdownHover: "#3662CC",
    menuHeading: "#FFFFFFF2",
    menuItem: "#FFFFFFF2",
    backgroundSpinner: '#FFFFFF',
    placeholder: "#ffffff4d",
    bgProfileNav: '#171717',
    bgProfileContent: '#171717' 
  },
  sidebar: {
    background: "#171717",
    backgroundAvatar: "#323232",
    item: {
      selected: "#3662CC",
      hover: "#323232",
      colorHover: "#ffffff",
      backgroundFocus: "#424242",
      borderColorFocus: "#2A67F4",
      backgroundHighlight: "#323232",
      borderColorHighlight: "#3662CC",
      backgroundOptions: "#292829",
    },
  },
};
