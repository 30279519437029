import { Dayjs } from 'dayjs';
import { isEmpty, keys, toPairs } from 'lodash';
import { HabitGoal, HabitGoals } from 'models/habits';
import memoizeOne from 'memoize-one';
import { _dayjs } from '../extended-dayjs';
import { FirstWeekDay } from 'models/single-progress';

export const isOneTimePerDayHabit = memoizeOne(
  ({ periodicity, symbol, value }: { periodicity: string; symbol: string; value: number }): boolean => {
    return periodicity === 'daily' && value === 1 && symbol === 'rep';
  },
);

export const getCurrentHabitGoal = memoizeOne(
  ({
    goals,
    date,
    firstDayOfWeek,
  }: {
    goals: HabitGoals;
    date: Dayjs;
    firstDayOfWeek: FirstWeekDay;
  }): HabitGoal | null | undefined => {
    const clonedDate = date.clone();
    let startDateWeek = clonedDate.startOf('week').format('DDMMYYYY');
    let endDateWeek = clonedDate.endOf('week').format('DDMMYYYY');
    const startDateMonth = clonedDate.startOf('month').format('DDMMYYYY');
    const endDateMonth = clonedDate.endOf('month').format('DDMMYYYY');

    if (firstDayOfWeek === 'monday') {
      startDateWeek = clonedDate.add(-1, 'day').startOf('week').add(1, 'day').format('DDMMYYYY');
      endDateWeek = clonedDate.add(-1, 'day').endOf('week').add(1, 'day').format('DDMMYYYY');
    }

    const keyMonth = `${startDateMonth}-${endDateMonth}`;
    const keyWeek = `${startDateWeek}-${endDateWeek}`;
    const keyDay = _dayjs(date).format('DDMMYYYY');

    let currentGoal: HabitGoal | null | undefined;

    

    if (!isEmpty(goals)) {
      if (goals[keyDay]) {
        currentGoal = goals[keyDay];
      }
      
      if (goals[keyWeek]) {
        const goalWeek = goals[keyWeek];
        if (goalWeek) {
          if (currentGoal) {
            currentGoal = getGoalCompare({ goalCurrent: goalWeek, goalCompare: currentGoal });
          } else {
            const timestampsWeek = _dayjs(goalWeek.createdAt).valueOf();
            if (timestampsWeek && timestampsWeek >= date.valueOf()) {
              currentGoal = goalWeek;
            }
          }
        }
      }
      if (goals[keyMonth]) {
        const goalMonth = goals[keyMonth];
        if (goalMonth) {
          if (currentGoal) {
            currentGoal = getGoalCompare({ goalCurrent: goalMonth, goalCompare: currentGoal });
          } else {
            const timestampsMonth = _dayjs(goalMonth.createdAt).valueOf();
            if (timestampsMonth && timestampsMonth >= date.valueOf()) {
              currentGoal = goalMonth;
            }
          }
        }
      }

      if (!currentGoal) {
        const _habitGoals = toPairs(goals).sort((item1, item2) => {
          const item1CreationTime = _dayjs(item1[1]?.createdAt).valueOf();
          const item2CreationTime = _dayjs(item2[1]?.createdAt).valueOf();

          if (item1CreationTime && item2CreationTime) {
            return item1CreationTime - item2CreationTime;
          } else if (item1CreationTime && !item2CreationTime) {
            return -1;
          } else if (!item1CreationTime && item2CreationTime) {
            return 1;
          } else {
            return 1;
          }
        });

        const firstGoal = _habitGoals[0];
        const timestampsFirstGoal = _dayjs(firstGoal[1].createdAt).valueOf();
        if (timestampsFirstGoal && date.valueOf() < timestampsFirstGoal) {
          return null;
        }

        const resultSearch = binarySearchHabitGoals({ listHabitGoal: _habitGoals, date });
        if (resultSearch) currentGoal = resultSearch[1];
      }
    }
   
    return currentGoal;
  },
);

export const getListHabitGoal = memoizeOne(
  ({
    goal,
    goals,
    startDate,
    firstDayOfWeek
  }: {
    goal: HabitGoal | null | undefined;
    goals: HabitGoals | null | undefined;
    startDate: number | null | undefined;
    firstDayOfWeek: FirstWeekDay | undefined
  }): HabitGoals => {
    const _firstDayOfWeek = firstDayOfWeek || 'sunday'
    const listHabitGoal = getListRawHabitGoal({ goal, goals, startDate });
    const sortedHabitGoalList = sortListHabitGoal(listHabitGoal);
    const filteredHabitGoalList = filterListHabitGoal({ sortedHabitGoalList, listHabitGoal });
    return getListHabitGoalByRangeDate({ filteredHabitGoalList, listHabitGoal, firstDayOfWeek: _firstDayOfWeek });
  },
);

const getListRawHabitGoal = memoizeOne(
  ({
    goal,
    goals,
    startDate,
  }: {
    goal: HabitGoal | null | undefined;
    goals: HabitGoals | null | undefined;
    startDate: number | null | undefined;
  }): HabitGoals => {
    const listRawHabitGoal: HabitGoals = {};
    if (goal) {
      const startDateFormatted = _dayjs(startDate).format('YYYYMMDD');
      const goalFormatted: HabitGoal = {
        ...goal,
        createdAt: _dayjs(startDateFormatted, 'DDMMYYYY').toISOString(),
      };
      listRawHabitGoal[startDateFormatted] = goalFormatted;
    }
    if (goals) {
      for (const [key, value] of toPairs(goals)) {
        listRawHabitGoal[key] = value;
      }
    }
    return listRawHabitGoal;
  },
);

const sortListHabitGoal = memoizeOne((listHabitGoals: HabitGoals): string[] => {
  return keys(listHabitGoals).sort((keyA, keyB) => {
    const goalACreationTime = _dayjs(listHabitGoals[keyA].createdAt).valueOf();
    const goalBCreationTime = _dayjs(listHabitGoals[keyB].createdAt).valueOf();
    if (goalACreationTime && goalBCreationTime) {
      return goalACreationTime - goalBCreationTime;
    } else if (goalACreationTime && !goalBCreationTime) {
      return -1;
    } else if (!goalACreationTime && goalBCreationTime) {
      return 1;
    }
    return 1;
  });
});

const filterListHabitGoal = memoizeOne(
  ({ listHabitGoal, sortedHabitGoalList }: { sortedHabitGoalList: string[]; listHabitGoal: HabitGoals }): string[] => {
    const listHabitGoalFiltered: string[] = [];
    for (let i = 0; i < sortedHabitGoalList.length; i++) {
      const dateKey = sortedHabitGoalList[i];
      const dateKeyPrev = sortedHabitGoalList[i - 1];
      const currentDate = listHabitGoal[dateKey]?.createdAt;
      const prevDate = listHabitGoal[dateKeyPrev]?.createdAt;
      const selectedDateKey = sortedHabitGoalList[i];
      if (currentDate) {
        if (prevDate) {
          if (_dayjs(currentDate).format('DDMMYYYY') === _dayjs(prevDate).format('DDMMYYYY')) {
            const timestampsTruth = _dayjs(currentDate).valueOf() > _dayjs(prevDate).valueOf();

            if (timestampsTruth) {
              listHabitGoalFiltered.splice(listHabitGoalFiltered.length - 1, 1, selectedDateKey);
            }
          } else {
            listHabitGoalFiltered.push(selectedDateKey);
          }
        } else {
          listHabitGoalFiltered.push(selectedDateKey);
        }
      }
    }
    return listHabitGoalFiltered;
  },
);

const getListHabitGoalByRangeDate = memoizeOne(
  ({
    filteredHabitGoalList,
    listHabitGoal,
    firstDayOfWeek,
  }: {
    filteredHabitGoalList: string[];
    listHabitGoal: HabitGoals;
    firstDayOfWeek: FirstWeekDay
  }): HabitGoals => {
    const habitGoalByRange: HabitGoals = {};
    filteredHabitGoalList.forEach((key) => {
      const { periodicity, createdAt } = listHabitGoal[key];
      let dateKey = '';
      if (createdAt) {
        if (periodicity !== 'daily') {
          const startOrEndOf = periodicity === 'weekly' ? 'week' : 'month';
          let startDate = _dayjs(createdAt).clone().startOf(startOrEndOf).format('DDMMYYYY');
          let endDate = _dayjs(createdAt).clone().endOf(startOrEndOf).format('DDMMYYYY');
          if(periodicity === 'weekly' && firstDayOfWeek === 'monday'){
            startDate = _dayjs(createdAt).clone().startOf(startOrEndOf).add(1,'day').format('DDMMYYYY')
            endDate = _dayjs(createdAt).clone().endOf(startOrEndOf).add(1,'day').format('DDMMYYYY')
          }
          dateKey = `${startDate}-${endDate}`;
        } else {
          dateKey = _dayjs(createdAt).format('DDMMYYYY');
        }
      }
      const newHabitGoal = listHabitGoal[key];
      if (newHabitGoal) {
        habitGoalByRange[dateKey] = newHabitGoal;
      }
    });
    return habitGoalByRange;
  },
);

const binarySearchHabitGoals = memoizeOne(
  ({ listHabitGoal, date }: { listHabitGoal: [string, HabitGoal][]; date: Dayjs }): [string, HabitGoal] | undefined => {
    try {
      let listHabitGoalKeyFiltered;
      const lengthOfListHabitGoal: number = listHabitGoal.length - 1;
      const middleIndex: number = Math.round(lengthOfListHabitGoal / 2);
      if (listHabitGoal.length === 1) {
        return listHabitGoal[0];
      }
      if (listHabitGoal.length > 0) {
        const habitGoalCurrent = listHabitGoal[middleIndex][1];
        const { createdAt } = habitGoalCurrent;
        if (createdAt) {
          const timestampsOfMiddleKey = _dayjs(createdAt).valueOf();
          const timestampsSelectedDate = date.valueOf();
          if (timestampsSelectedDate >= timestampsOfMiddleKey) {
            listHabitGoalKeyFiltered = listHabitGoal.splice(middleIndex, lengthOfListHabitGoal);
          } else {
            listHabitGoalKeyFiltered = listHabitGoal.splice(0, middleIndex);
          }
          return binarySearchHabitGoals({ listHabitGoal: listHabitGoalKeyFiltered, date });
        }
      }
    } catch (error: any) {
      throw new Error(error);
    }
  },
);

const getGoalCompare = memoizeOne(
  ({
    goalCurrent,
    goalCompare,
  }: {
    goalCurrent: HabitGoal | null | undefined;
    goalCompare: HabitGoal | null | undefined;
  }): HabitGoal | null | undefined => {
    const timestampsOfGoalCurrent = goalCurrent?.createdAt?.valueOf();
    const timestampsOfGoalCompare = goalCompare?.createdAt?.valueOf();
    if (timestampsOfGoalCompare && timestampsOfGoalCurrent) {
      return timestampsOfGoalCurrent > timestampsOfGoalCompare ? goalCurrent : goalCompare;
    } else if (timestampsOfGoalCompare && !timestampsOfGoalCurrent) {
      return goalCompare;
    } else if (!timestampsOfGoalCompare && timestampsOfGoalCurrent) {
      return goalCurrent;
    }
    return goalCurrent;
  },
);
