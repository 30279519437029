import { Box, Text } from '@chakra-ui/layout';
import { CircularProgress, CircularProgressLabel, useColorModeValue } from '@chakra-ui/react';
import Icon from 'components/common/Icon';
import { useThemeData } from 'hooks/useThemeData';
import { CalendarClass, DateToCalendarInfo } from 'models/single-progress';
import React, { memo, ReactElement, useCallback } from 'react';
import { _dayjs } from 'tools/extended-dayjs';
import { darkMode, lightMode } from 'tools/common/colorPreferences';
import { fontSize } from 'tools/common/fontSizePreferences';

interface CalendarMonthlyItemProps {
  dataOnCalendar: [string, DateToCalendarInfo];
  habitColor: string | null | undefined;
}

const CalendarMonthlyItem: React.FC<CalendarMonthlyItemProps> = ({
  dataOnCalendar,
  habitColor,
}: CalendarMonthlyItemProps): ReactElement => {
  const { colorScheme } = useThemeData();

  const colorCalendarText: string = useColorModeValue(lightMode.TEXT_COLOR, darkMode.TEXT_COLOR);
  const colorBlockedCalendarText: string = useColorModeValue(
    lightMode.COLOR_CARD_OVER_VIEW,
    darkMode.COLOR_CARD_OVER_VIEW,
  );

  const accentColor = !!habitColor ? habitColor : colorScheme.accent.primary;

  const bgFailedCalendarText: string = useColorModeValue(lightMode.FAILED_COLOR, darkMode.FAILED_COLOR);
  const trackColor = useColorModeValue(lightMode.TRACK_COLOR, darkMode.TRACK_COLOR);

  const cssDayInCalendar = (): React.CSSProperties => {
    const currentClass = dataOnCalendar[1]?.class;
    let cssStyle: React.CSSProperties = {};
    cssStyle.cursor = 'pointer';
    cssStyle.color = '#fff';
    switch (currentClass) {
      case CalendarClass.PREV_COMPLETED:
        cssStyle.backgroundColor = accentColor;
        cssStyle.borderTopLeftRadius = '100px';
        cssStyle.borderBottomLeftRadius = '100px';
        break;
      case CalendarClass.NEXT_COMPLETED:
        cssStyle.backgroundColor = accentColor;
        cssStyle.borderTopRightRadius = '100px';
        cssStyle.borderBottomRightRadius = '100px';
        break;
      case CalendarClass.MIDDLE_COMPLETED:
        cssStyle.backgroundColor = accentColor;
        break;
      case CalendarClass.SINGLE_COMPLETED:
        cssStyle.backgroundColor = accentColor;
        cssStyle.width = '38px';
        cssStyle.borderRadius = '50%';
        break;
      case CalendarClass.SKIP:
        cssStyle.backgroundColor = '#2a67f480';
        cssStyle.width = '38px';
        cssStyle.borderRadius = '50%';
        break;
      case CalendarClass.FAIL:
        cssStyle.backgroundColor = bgFailedCalendarText;
        cssStyle.width = '38px';
        cssStyle.borderRadius = '50%';
        break;
      case CalendarClass.IN_PROGRESS:
        cssStyle = {};
        break;
      case CalendarClass.BLOCK:
        cssStyle.color = colorBlockedCalendarText;
        cssStyle.cursor = 'not-allowed';
        break;
      default:
        cssStyle.color = colorCalendarText;
        break;
    }
    return cssStyle;
  };

  const textDateFormatted = useCallback(() => {
    return _dayjs(dataOnCalendar[0]).format('D');
  }, [dataOnCalendar]);

  const cssStyleBefore = (): React.CSSProperties => {
    const currentDate: string = dataOnCalendar[0];
    const cssBefore: React.CSSProperties =
      Number(_dayjs(currentDate).format('D')) === 1
        ? {
            content: '""',
            display: 'inline-block',
            width: '3px',
            height: '3px',
            borderRadius: '50%',
            background: '#f1a121',
            position: 'absolute',
            top: '-15%',
            left: '50%',
            transform: 'translateX(-50%)',
            zIndex: 98,
          }
        : {
            content: '""',
          };
    return cssBefore;
  };

  const dayInCalendar = () => {
    const classCurrent = dataOnCalendar[1]?.class;
    if (classCurrent === CalendarClass.IN_PROGRESS) {
      return (
        <CircularProgress
          value={(dataOnCalendar[1]?.inProgress || 0) < 1 ? 1 : dataOnCalendar[1]?.inProgress || 0}
          color={accentColor || ''}
          trackColor={trackColor}
          size="38px"
          fontWeight="500"
        >
          <CircularProgressLabel fontSize="15px">{textDateFormatted()}</CircularProgressLabel>
        </CircularProgress>
      );
    }
    if (classCurrent === CalendarClass.SKIP) {
      return <Icon name="ic-single-progress-skipped" fill={accentColor} />;
    }
    if (classCurrent === CalendarClass.FAIL) {
      return <Icon name="ic-single-progress-failed" fill={colorScheme.label.primary} />;
    }
    return (
      <Text style={cssDayInCalendar()} fontSize={fontSize.FONT_S15} fontWeight="500" textAlign="center" width="100%">
        {textDateFormatted()}
      </Text>
    );
  };

  return (
    <Box
      textAlign="center"
      height="38px"
      position="relative"
      display="flex"
      justifyContent="center"
      alignItems="center"
      _before={cssStyleBefore()}
      fontWeight="500"
      lineHeight="38px"
    >
      {dayInCalendar()}
    </Box>
  );
};
export default memo(CalendarMonthlyItem);
