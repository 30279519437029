import { Grid } from '@chakra-ui/layout';
import SEO from 'components/common/SEO';
import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

interface Props {
  children?: ReactNode;
}

const Main: React.FC<Props> = ({ children }: Props): ReactElement => {
  const [title, setTitle] = useState<string>('');

  useEffect(() => {
    setTitle('title');
  }, []);

  return (
    <Grid className="main-app">
      <SEO title={title} />
      {children}
    </Grid>
  );
};

export default Main;
