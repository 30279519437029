import { Box, Grid, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/react';
import React, { memo, ReactElement } from 'react';
import { fontSize } from 'tools/common/fontSizePreferences';
import { darkMode, lightMode } from 'tools/common/colorPreferences';
import { fontWeight } from 'tools/common/fontWeightPreferences';
import { useThemeData } from 'hooks/useThemeData';
import Icon from 'components/common/Icon';
import { _dayjs } from 'tools/extended-dayjs';
import { CalendarClass, CalendarHeatMapInfo } from 'models/single-progress';

interface CalendarYearlyItemProps {
  CalendarHeatMapItem: CalendarHeatMapInfo;
  habitColor: string;
}

const CalendarYearlyItem: React.FC<CalendarYearlyItemProps> = ({
  CalendarHeatMapItem,
  habitColor,
}: CalendarYearlyItemProps): ReactElement => {
  const { colorScheme } = useThemeData();

  const colorBlockedCalendarText: string = useColorModeValue(lightMode.BORDER_BOX_COLOR, darkMode.BORDER_BOX_COLOR);
  const accentColor = useColorModeValue(lightMode.ACCENT_COLOR, darkMode.ACCENT_COLOR);

  const successColor = !!habitColor ? habitColor : accentColor;
  const failedColor = colorScheme.universal.black;
  const skippedColor = !!habitColor ? habitColor : accentColor;

  const cssStyle = (currentClass: string) => {
    const cssStyle: React.CSSProperties = {};
    switch (currentClass) {
      case CalendarClass.BLOCK:
        cssStyle.background = colorBlockedCalendarText;
        break;
      case CalendarClass.NONE:
        cssStyle.background = colorBlockedCalendarText;
        break;
      case CalendarClass.FAIL:
        cssStyle.backgroundColor = failedColor;
        break;
      case CalendarClass.SKIP:
        cssStyle.backgroundColor = convertHexToRgba(skippedColor);
        break;
      default:
        cssStyle.backgroundColor = successColor;
        break;
    }
    return cssStyle;
  };

  const convertHexToRgba = (hex: string) => {
    const r = parseInt(hex.slice(1, 3), 16);
    const g = parseInt(hex.slice(3, 5), 16);
    const b = parseInt(hex.slice(5, 7), 16);
    return `rgba(${r},${g},${b},0.2)`;
  };

  return (
    <Grid textAlign="left" fontSize={fontSize.FONT_S22} gridTemplateColumns="45px 1fr" fontWeight={fontWeight.BOLD}>
      <Text
        mt="3px"
        fontSize="13px"
        lineHeight="15.73px"
        color={colorScheme.label.secondary}
        textOverflow="ellipsis"
        whiteSpace="nowrap"
        overflowY="hidden"
        fontWeight="400"
      >
        {_dayjs(CalendarHeatMapItem.month).format('MMM')}
      </Text>
      <Box padding="0 0 0 0">
        {Array.from(CalendarHeatMapItem.dataCalendar).map((data, index) => {
          return (
            <Box
              key={index}
              position="relative"
              style={cssStyle(data[1].class)}
              display="inline-block"
              minW="21px"
              minH="21px"
              w="calc(100% / 31 - 0.5vw)"
              // h="calc(100% / 31 - 0.5vw)"
              // maxW="31px"
              pt="calc(100% / 31 - 0.5vw)"
              borderRadius="3px"
              margin="0 5px"
            >
              {data[1].class === CalendarClass.FAIL && (
                <Box
                  w="70%"
                  h="70%"
                  maxW="100%"
                  maxH="100%"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  display="flex"
                >
                  <Icon name="ic-single-progress-failed" width="100%" fill={'white'} />
                </Box>
              )}
              {data[1].class === CalendarClass.SKIP && (
                <Box
                  w="80%"
                  h="80%"
                  maxW="100%"
                  maxH="100%"
                  position="absolute"
                  top="50%"
                  left="50%"
                  transform="translate(-50%, -50%)"
                  display="flex"
                >
                  <Icon name="ic-single-progress-skipped" width="200%" fill={skippedColor} />
                </Box>
              )}
            </Box>
          );
        })}
      </Box>
    </Grid>
  );
};
export default memo(CalendarYearlyItem);
