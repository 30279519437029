import { Location } from 'history';
import React, { ReactElement, Suspense } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Main from 'views/main/main.views';
import MainLayout from 'components/layouts/main.layout';
import NotFound from './not-found/not-found.view';

interface LocationState {
  background: Location;
}

const Routes: React.FC = (): ReactElement => {
  const location = useLocation<LocationState>();

  return (
    <>
      <Switch location={location}>
        <Route exact path="/">
          <Suspense fallback={<MainLayout />}>
            <Main />
          </Suspense>
        </Route>
        <Route exact path="/:shareId">
          <Suspense fallback={<MainLayout />}>
            <Main />
          </Suspense>
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
    </>
  );
};

export default Routes;
