import React, { useEffect, useState } from 'react';

interface Props extends React.SVGProps<SVGSVGElement> {
  name?: string;
  folderName?: string;
}

const Icon: React.FC<Props> = ({ name, folderName, ...rest }: Props) => {
  const [iconModule, setIconModule] = useState<any>(null);

  useEffect(() => {
    let cancel = false;
    let folderIcon = `${name}.svg`;
    if (folderName) {
      folderIcon = `${folderName}/${name}.svg`;
    }
    import(
      /* webpackMode: "eager"  */
      /* webpackChunkName: "SvgsSystem" */
      /* webpackPreload: true */
      `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/${folderIcon}`
    )
      .then((module) => {
        /* Persist data in state */
        if (cancel) return;
        setIconModule(module);
      })
      .catch((error) => {
        import(
          /* webpackMode: "eager"  */
          /* webpackChunkName: "SvgsSystem" */
          /* webpackPreload: true */
          `!!@svgr/webpack?-svgo,+titleProp,+ref!../../assets/icons/habit-icons/ic-habit-icon-default.svg`
        ).then((module) => {
          if (cancel) return;
          setIconModule(module);
        });
      });
    return () => {
      //avoid memory leak
      cancel = true;
    };
  }, [folderName, name]);

  const renderIcon = () => {
    if (!iconModule) return null;
    const Component = iconModule.default;

    return <Component {...rest} />;
  };

  return <>{renderIcon()}</>;
};
export default Icon;
