/* eslint-disable prefer-rest-params */
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import App from 'App';
import 'localization/i18n';
import React from 'react';
// import 'react-day-picker/lib/style.css';
import ReactDOM from 'react-dom';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { chakraTheme } from 'theme';
import ThemeProvider from 'theme/theme.provider';
import './styles/main.css';

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider>
      <ChakraProvider theme={chakraTheme}>
        <ColorModeScript initialColorMode={chakraTheme.config.initialColorMode} key="chakra-ui-no-flash" />
        {/* <Provider> */}
        <ThemeProvider>
          <Router>
            <App />
          </Router>
        </ThemeProvider>
        {/* </Provider> */}
      </ChakraProvider>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
