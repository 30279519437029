import { Box, Text } from '@chakra-ui/layout';
import { Grid } from '@chakra-ui/react';
import CalendarMonthlyItem from 'components/ui/single-progress/main/calendar/CalendarMonthlyItem';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { fontSize } from 'tools/common/fontSizePreferences';
import { useTranslation } from 'react-i18next';
import { _dayjs } from 'tools/extended-dayjs';
import { DateToCalendarInfo, FirstWeekDay } from 'models/single-progress';
interface SingleProgressCalendarProps {
  calendarGridMapInfo: Map<string, DateToCalendarInfo>;
  habitColor: string | null | undefined;
  firstDayOfWeek: FirstWeekDay
}

const SingleProgressCalendar: React.FC<SingleProgressCalendarProps> = ({
  calendarGridMapInfo,
  habitColor,
  firstDayOfWeek
}: SingleProgressCalendarProps): ReactElement => {
  const { i18n } = useTranslation();
  const [weekDays, setWeekDays] = useState<string[]>([]);
  const location = useLocation();

  useEffect(() => {
    const weekDays = [];
    const currentDate = new Date();
    // first day of week
    firstDayOfWeek === 'monday'
      ? currentDate.setDate(currentDate.getDate() - currentDate.getDay() + 1)
      : currentDate.setDate(currentDate.getDate() - currentDate.getDay());
    for (let i = 0; i < 7; i++) {
      weekDays.push(_dayjs(currentDate).locale(i18n.language).format('dd'));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    if (!!location.search) {
      setWeekDays([...weekDays, ...weekDays]);
    } else {
      setWeekDays([...weekDays]);
    }
  }, [location.search, firstDayOfWeek, i18n.language]);

  return (
    <Box>
      <Grid templateColumns={!!location.search ? 'repeat(14, 1fr)' : 'repeat(7, 1fr)'} mb="16px">
        {weekDays.map((weekday, index) => {
          return (
            <Text fontSize={fontSize.FONT_S11} fontWeight="500" color="#7B7C7C" textAlign="center" key={`a-${index}`}>
              {weekday}
            </Text>
          );
        })}
      </Grid>
      <Grid templateColumns={!!location.search ? 'repeat(14, 1fr)' : 'repeat(7, 1fr)'} gridRowGap="16px">
        {Array.from(calendarGridMapInfo).map((data, index) => {
          return <CalendarMonthlyItem key={index} dataOnCalendar={data} habitColor={habitColor} />;
        })}
      </Grid>
    </Box>
  );
};
export default memo(SingleProgressCalendar);
