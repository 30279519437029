import { Box, Flex, Text } from '@chakra-ui/layout';
import { useColorModeValue } from '@chakra-ui/react';
import { ApexOptions } from 'apexcharts';
import dayjs from 'dayjs';
import { useThemeData } from 'hooks/useThemeData';
import { Habit, HabitGoal } from 'models/habits';
import { ChartSeriesInfo, FirstWeekDay, HabitProgressMapInfo } from 'models/single-progress';
import React, { memo, ReactElement, useEffect, useState } from 'react';
import ReactApexCharts from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { getDataChartTrend } from 'tools/single-progress';
import { SIBaseUnit, UNIT_SYMBOL } from 'tools/si-unit/SIBaseUnit';
import { darkMode, lightMode } from 'tools/common/colorPreferences';
import { fontSize } from 'tools/common/fontSizePreferences';
import { fontWeight } from 'tools/common/fontWeightPreferences';
import { optionChart } from './chart_trend.model.single_progress';
import HabitChartTrendEmpty from './habit_chart_trend_empty';

interface HabitChartTrendPros {
  habit: Habit;
  habitColor: string | null | undefined;
  habitProgressInfo: HabitProgressMapInfo;
  firstDayOfWeek: FirstWeekDay;
}

enum FilterOptions {
  DAILY = 'D',
  WEEKLY = 'W',
  MONTHLY = 'M',
}

const HabitChartTrend: React.FC<HabitChartTrendPros> = ({
  habit,
  habitColor,
  habitProgressInfo,
  firstDayOfWeek,
}: HabitChartTrendPros): ReactElement => {
  const { t } = useTranslation();
  const { colorScheme } = useThemeData();
  const colorSecondaryChart = useColorModeValue(lightMode.TRACK_COLOR, darkMode.TRACK_COLOR);
  const [avgLogValue, setAvgLogValue] = useState<number | null>(null);

  const [textFilterLogValue, setTextFilterLogValue] = useState<string>('');
  const [symbolCurrent, setSymbolCurrent] = useState<string>('');
  const [filterOptions, setFilterOptions] = useState<string[]>(['D', 'W', 'M']);
  const [currentOptionChart, setCurrentOptionChart] = useState<string>(FilterOptions.MONTHLY);
  const [chartOption, setChartOption] = useState<ApexOptions>(optionChart);
  const [chartSeries, setChartSeries] = useState<ChartSeriesInfo[]>([]);
  const [isChartDisplay, setIsChardDisplay] = useState<boolean>(false);

  const colorBarChart = habitColor || colorScheme.accent.primary;
  const colorLineChart = habit?.habitType?.habitType === 'bad' ? '#D23737' : habitColor;
  const habitGoalCurrent = habitProgressInfo.dateToHabitGoalCurrentMap?.get(dayjs().format('YYYY-MM-DD')) as
    | HabitGoal
    | null
    | undefined;

  const onChangeFilter = (options: string) => {
    setCurrentOptionChart(options);
  };

  useEffect(() => {
    if (!habit?.goal && !habit?.goals) {
      setFilterOptions(['W', 'M']);
    } else {
      setFilterOptions(['D', 'W', 'M']);
    }
    let filterTextType = t('common.dailyAverage');
    if (currentOptionChart === FilterOptions.MONTHLY) {
      filterTextType = t('common.monthlyAverage');
    }
    if (currentOptionChart === FilterOptions.WEEKLY) {
      filterTextType = t('common.weeklyAverage');
    }
    setTextFilterLogValue(filterTextType);
    const dataChart = getDataChartTrend(
      habit,
      currentOptionChart,
      habitProgressInfo,
      habitGoalCurrent,
      firstDayOfWeek || 'sunday',
    );

    if (dataChart) {
      const { dataBarChart, dataLineChart, dateCategories, symbolDefault, isChartDisplay, sumLogValue } = dataChart;
      
      isChartDisplay && setIsChardDisplay(isChartDisplay)
    
      const limitLength = 31;
      const dataBarChartView =
        dataBarChart && dataBarChart?.length > limitLength
          ? dataBarChart.slice(dataBarChart.length - limitLength, dataBarChart.length)
          : dataBarChart;

      const dataLineChartView =
        dataLineChart && dataLineChart?.length > limitLength
          ? dataLineChart.slice(dataLineChart.length - limitLength, dataLineChart.length)
          : dataLineChart;

      const dateCategoriesView =
        dateCategories && dateCategories?.length > limitLength
          ? dateCategories.slice(dateCategories.length - limitLength, dateCategories.length)
          : dateCategories;

      const textSymbol: string =
        !symbolDefault ||
        symbolDefault === 'undefined' ||
        symbolDefault === UNIT_SYMBOL.REP ||
        symbolDefault === UNIT_SYMBOL.STEP
          ? String(t('measurement.unitTimes'))
          : symbolDefault;

      if (dataBarChart && dataBarChart.length > 0) {
        // console.log('sum ==>', sumLogValue);
        if (sumLogValue) {
          let avgLog: number = Math.round((sumLogValue / (dataBarChart?.length || 1)) * 100) / 100;
          if (
            symbolDefault === UNIT_SYMBOL.REP ||
            symbolDefault === UNIT_SYMBOL.STEP ||
            symbolDefault === 'steps' ||
            !(habit.goal || habit.goals)
          ) {
            avgLog = Math.floor(sumLogValue / dataBarChart?.length);
          }
          setAvgLogValue(avgLog);
          setIsChardDisplay(true);
          // console.log('setIsChardDisplay 2 =>', true);
        } else {
          // console.log('setIsChardDisplay 3 =>', false);
          setIsChardDisplay(false)
        }
      }

      const series: ChartSeriesInfo[] = [
        {
          name: 'Log Value',
          type: 'bar',
          data: dataBarChartView,
        },
        {
          name: 'Goal Value',
          type: 'line',
          data: dataLineChartView,
        },
      ];

      setSymbolCurrent(SIBaseUnit[String(symbolDefault)]?.display);
      // chart options
      let tickAmount = undefined;
      if (dataBarChartView) {
        const maxValueBarChart = Math.max(...dataBarChartView);
        if (maxValueBarChart > 5) tickAmount = 5;
      }
      if (dataLineChartView && !tickAmount) {
        const maxValueLineChart = Math.max(...dataLineChartView);
        if (maxValueLineChart > 5) tickAmount = 5;
      }

      const gridBorderColor = colorSecondaryChart;

      const tooltip: ApexTooltip = {
        custom: ({ series, seriesIndex, dataPointIndex, w }): string => {
          return `<div class="apexcharts-tooltip_arrow-box" style="background: ${colorBarChart}" >
              <span style="font-size:11px; color: #ffffff80; margin-right: 7px; font-weight: 500">
                ${w.globals.categoryLabels[dataPointIndex]}
              </span>
              <span style="font-size: "13px"; color: "white" ; font-weight: 500">
                ${Math.round(series[0][dataPointIndex] * 100) / 100 || 0} ${textSymbol}
              </span>
              <div class="apexcharts-tooltip_sub-arrow-box" style="background: ${colorBarChart}"></div>
            </div>`;
        },
      };

      const xaxisBorder = {
        show: true,
        color: colorSecondaryChart,
        offsetX: -1,
        offsetY: 0,
      };

      const yaxisBorder = {
        show: true,
        color: colorSecondaryChart,
        offsetX: 1,
        offsetY: -1,
      };

      const chartOptions: ApexOptions = {
        ...optionChart,
        grid: {
          ...optionChart.grid,
          borderColor: gridBorderColor,
        },
        xaxis: {
          ...optionChart.xaxis,
          axisBorder: xaxisBorder,
          categories: dateCategoriesView,
        },
        yaxis: {
          ...optionChart.yaxis,
          axisBorder: yaxisBorder,
          tickAmount,
        },
        tooltip,
        colors: [colorBarChart, colorLineChart],
        fill: {
          colors: [colorBarChart],
          type: 'solid',
          opacity: [1, 1],
        },
      };
      setChartOption(chartOptions);
      setChartSeries(series);
    }
  }, [
    colorSecondaryChart,
    currentOptionChart,
    habit,
    habitProgressInfo,
    t,
    colorBarChart,
    colorLineChart,
    habitGoalCurrent,
    firstDayOfWeek,
  ]);

  const textUnit = (symbol: string | undefined) => {
    if (!symbol || symbol === 'undefined' || symbol === UNIT_SYMBOL.REP || symbol === 'times') {
      return t('measurement.unitTimes');
    }
    if (symbol === UNIT_SYMBOL.STEP || symbol === 'steps') {
      return t('measurement.unitSteps');
    }
    return symbol;
  };

  const handleOnMouseEnter = (e: React.MouseEvent) => {
    const elTooltip: Element | null | any = document.querySelector('.apexcharts-tooltip');
    const elXaxistooltip: Element | null | any = document.querySelector('.apexcharts-xaxistooltip');
    const widthTooltip = elTooltip?.offsetWidth;
    const widthXaxis = elXaxistooltip?.offsetWidth;
    const xaxisOffsetLeft = elXaxistooltip?.offsetLeft;
    const position = xaxisOffsetLeft + widthXaxis / 2 - widthTooltip / 2;
    const heightLine: number | undefined = document.querySelector('.apexcharts-yaxis')?.getBoundingClientRect()?.height;
    const heightTooltip = 38;
    if (elTooltip && elXaxistooltip && position && heightLine) {
      elTooltip.style.left = position + 'px';
      elTooltip.style.height = heightLine + heightTooltip + 'px';
    }
  };

  return (
    <Box width={'100%'} position="relative">
      <Box
        textAlign="left"
        mb="12px"
        p={isChartDisplay ? '15px 16px 17px 16px' : '15px 16px 0px 16px'}
        borderRadius="10px"
        border={`1px solid ${colorScheme.platform.border}`}
        color={colorScheme.label.secondary}
        fontSize={fontSize.FONT_S22}
        fontWeight={fontWeight.BOLD}
        backgroundColor={colorScheme.background.primary[1]}
      >
        <Flex justifyContent="space-between" alignItems="center">
          <Box>
            <Text
              fontSize={fontSize.FONT_S13}
              fontWeight={fontWeight.MEDIUM}
              color={colorScheme.label.secondary}
              lineHeight="15.51px"
              textTransform="uppercase"
              mb="5px"
            >
              {textFilterLogValue}
            </Text>
            <Box>
              <Text
                display="inline-block"
                fontSize={isChartDisplay ? '29px' : '25px'}
                lineHeight="34.61px"
                fontWeight="600"
                color={colorScheme.label.primary}
              >
                {isChartDisplay ? avgLogValue : '---'}
              </Text>
              
              {isChartDisplay && 
              <Text
                display="inline-block"
                fontSize="17px"
                lineHeight="20.29px"
                fontWeight="500px"
                pl="5px"
                color={colorScheme.label.primary}
              >
                {textUnit(symbolCurrent)}
              </Text>
              }
            </Box>
          </Box>
        </Flex>
        <Box
          onMouseMove={(e) => {
            handleOnMouseEnter(e);
          }}
        >
          {isChartDisplay ? (
            <ReactApexCharts options={chartOption} series={chartSeries} width="100%" height={250} />
          ) : (
            <HabitChartTrendEmpty />
          )}
        </Box>
        {isChartDisplay && 
        <Box textAlign="center">
          {filterOptions.map((option: string, index: number) => {
            return (
              <Box
                key={index}
                style={
                  option === currentOptionChart
                    ? {
                        backgroundColor: '#f5f5f5',
                        color: '#000',
                      }
                    : {}
                }
                onClick={() => {
                  onChangeFilter(option);
                }}
                display="inline-block"
                height="27px"
                lineHeight="27px"
                width="71px"
                fontSize="12px"
                textTransform="uppercase"
                borderRadius="100px"
                color={colorScheme.label.primary}
                fontWeight="600"
                _hover={{
                  background: '#fff',
                  color: '#000',
                  transform: '0.1s',
                }}
              >
                {option}
              </Box>
            );
          })}
        </Box>
        }
      </Box>
    </Box>
  );
};
export default memo(HabitChartTrend);
