import { Box, Text } from '@chakra-ui/react';
import { useThemeData } from 'hooks/useThemeData';
import React, { ReactElement } from 'react';
// import { useHistory } from 'react-router-dom';

const NotFound: React.FC = (): ReactElement => {
  const { typography } = useThemeData();
  // const history = useHistory();
  return (
    <Box width="100vw" height="100vh" display="flex" justifyContent="center" alignItems="center">
      <Box textAlign="center">
        <Text {...typography.emphasized.title.large}>404 Not Found</Text>
        {/* <Text mt="12px" {...typography.emphasized.title[3]} color={colorScheme.accent.primary} onClick={() => history.push('/')}>
          Back
        </Text> */}
      </Box>
    </Box>
  );
};
export default NotFound;
