// import { convert, getBaseUnitFromType, getType } from './SIUnitUtils';
import dayjs from 'dayjs';
import { UnitType } from 'models/common';
import { Habit, HabitGoal, HabitGoals } from 'models/habits';
import { HabitLog } from 'models/habit-logs';
import { getCurrentHabitGoal } from 'tools/habit-progress';
import {
  convert,
  getBaseUnitFromType as newGetBaseUnitFromType,
  getType as newGetType,
} from 'tools/si-unit/si-unit-utils';
import { getMonthKey, getWeekExactlyByMonth, getWeekKey } from '.';
import { isArray } from 'lodash';
import { FirstWeekDay } from 'models/single-progress';

interface IHabitLogsFiltered {
  habitLog: HabitLog;
  logValue: number;
  isNoGoal?: boolean;
}

const getLogValue = (
  logValueConverted: number,
  habitLogsFiltered: Map<string, IHabitLogsFiltered>,
  dateKey: string,
): number => {
  return habitLogsFiltered.get(dateKey)
    ? Number(habitLogsFiltered.get(dateKey)?.logValue) + Number(logValueConverted)
    : Number(logValueConverted) || 0;
};

const setHabitLogComputedMap = (payload: {
  dateToHabitLogsFilteredMap: Map<string, IHabitLogsFiltered>,
  habitGoal: HabitGoal | null | undefined,
  habitLog: HabitLog,
  habitLogUnitType: UnitType,
  dateKey: {
    dayKey: string,
    weekKey: string,
    weekKeyExactlyByMonth: string,
    monthKey: string
  },
  logValues: {
    dailyLogValue: number,
    weekLogValue: number,
    weekExactlyByMonthLogValue: number,
    monthLogValue: number
  }
}) => {
  const {
    dateToHabitLogsFilteredMap,
    habitGoal,
    habitLog,
    habitLogUnitType,
    dateKey: { dayKey, weekKey, weekKeyExactlyByMonth, monthKey },
    logValues: { dailyLogValue, weekLogValue, weekExactlyByMonthLogValue, monthLogValue }
  } = payload
  if (habitGoal?.unit?.symbol) {
    const habitSymbol = habitGoal.unit.symbol;
    const isSymbolSame: boolean = newGetType({ unitSymbol: habitSymbol }) === habitLogUnitType;
    if (isSymbolSame) {
      dateToHabitLogsFilteredMap.set(dayKey, { habitLog, logValue: dailyLogValue });
      dateToHabitLogsFilteredMap.set(weekKey, { habitLog, logValue: weekLogValue });
      dateToHabitLogsFilteredMap.set(weekKeyExactlyByMonth, { habitLog, logValue: weekExactlyByMonthLogValue });
      dateToHabitLogsFilteredMap.set(monthKey, { habitLog, logValue: monthLogValue });
    }
  }
  if (!habitGoal) {
    dateToHabitLogsFilteredMap.set(dayKey, { habitLog, logValue: dailyLogValue, isNoGoal: true });
    dateToHabitLogsFilteredMap.set(weekKey, { habitLog, logValue: weekLogValue, isNoGoal: true });
    dateToHabitLogsFilteredMap.set(weekKeyExactlyByMonth, { habitLog, logValue: weekExactlyByMonthLogValue, isNoGoal: true });
    dateToHabitLogsFilteredMap.set(monthKey, { habitLog, logValue: monthLogValue, isNoGoal: true });
  }
}

export const getHabitLogs = (
  habit: Habit,
  habitLogs: HabitLog[],
  listHabitGoal: HabitGoals,
  firstDayOfWeek: FirstWeekDay,
): Map<string, IHabitLogsFiltered> => {
  if (!habitLogs || !Object.keys(listHabitGoal).length || !isArray(habitLogs)) return new Map();
  const habitStartDate: number | null | undefined = habit.startDate;
  const dateToHabitLogsFilteredMap: Map<string, IHabitLogsFiltered> = new Map<string, IHabitLogsFiltered>();
  const logType = (habit.logInfo?.type && habit.logInfo?.type !== 'manual') || habit.logInfo?.links ? 'auto' : 'manual';

  if (habitStartDate) {
    habitLogs.forEach((rawHabitLog) => {
      let habitLog = rawHabitLog;
      if (isArray(rawHabitLog)) {
        const [logId, log] = rawHabitLog;
        habitLog = { ...log, ...{ id: logId } }
      }

      if (habitLog && habitLog.unitSymbol) {
        const habitGoal: HabitGoal | null | undefined = getCurrentHabitGoal({
          goals: listHabitGoal,
          date: dayjs(habitLog.startAt),
          firstDayOfWeek,
        });
        const timesTampsHabitStartDate: number = dayjs(dayjs(habitStartDate).format('YYYY-MM-DD')).valueOf();
        const timesTampsHabitLog: number = dayjs(habitLog.startAt).valueOf();
        const isConditionGetHabitLog: boolean = timesTampsHabitLog >= timesTampsHabitStartDate;
        const habitLogType: string = habitLog?.type !== 'manual' ? 'auto' : 'manual';
        const habitLogUnitType: UnitType = newGetType({ unitSymbol: habitLog.unitSymbol });
        const baseUnitSymbolOfLog: string = newGetBaseUnitFromType({ type: habitLogUnitType });

        if (isConditionGetHabitLog && logType === habitLogType && habitLog?.unitSymbol === baseUnitSymbolOfLog) {
          const dayKey: string = dayjs(habitLog.startAt).format('DDMMYYYY');
          const weekKey: string = getWeekKey(dayjs(habitLog.startAt), firstDayOfWeek);
          const weekKeyExactlyByMonth = getWeekExactlyByMonth(dayjs(habitLog.startAt));
          const monthKey: string = getMonthKey(dayjs(habitLog.startAt));
          const _habitLogValue = typeof habitLog.value === 'number' ? habitLog.value : 0;
          const logValueConverted = convert({
            source: habitLog.unitSymbol,
            target: baseUnitSymbolOfLog,
            value: _habitLogValue,
          });
          const dailyLogValue: number = getLogValue(logValueConverted, dateToHabitLogsFilteredMap, dayKey);
          const weekLogValue: number = getLogValue(logValueConverted, dateToHabitLogsFilteredMap, weekKey);
          const weekExactlyByMonthLogValue: number = getLogValue(
            logValueConverted,
            dateToHabitLogsFilteredMap,
            weekKeyExactlyByMonth,
          );
          const monthLogValue: number = getLogValue(logValueConverted, dateToHabitLogsFilteredMap, monthKey);

          setHabitLogComputedMap({
            dateToHabitLogsFilteredMap,
            habitGoal,
            habitLog,
            habitLogUnitType,
            dateKey: {
              dayKey,
              weekKey,
              weekKeyExactlyByMonth,
              monthKey
            },
            logValues: {
              dailyLogValue,
              weekLogValue,
              weekExactlyByMonthLogValue,
              monthLogValue
            }
          })
        }
      }
    });
  }
  return dateToHabitLogsFilteredMap;
};
